import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingComponent } from './billing.component';
import { IBmtBillingState } from '../store/billing/state';
import { initialStateMaBillingState } from '../store/billing/initialState';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientSearchModule } from '@roosevelt/client-ui-lib';
import { AvailableReportsModule, BillingModule as BillingLibraryModule } from '@roosevelt/billing-lib';
import { BillingContainer } from './billing.container';
import { AchPaymentComponent } from './ach-payment/ach-payment.component';
import { AchPayerAddressComponent } from './ach-payer-address/ach-payer-address.component';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { RadioModule } from '@roosevelt/common-ui-lib/radio';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';
import { CheckboxModule } from '@roosevelt/common-ui-lib/checkbox';
import { Lux } from '@roosevelt/common-ui-lib/core';

@NgModule({
  declarations: [BillingComponent, BillingContainer, AchPaymentComponent, AchPayerAddressComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    RadioModule,
    ReactiveFormsModule,
    ClientSearchModule,
    LoaderModule,
    FormsModule,
    BillingLibraryModule,
    AvailableReportsModule,
    SelectModule,
    LoaderModule,
    DialogModule,
    CheckboxModule
  ]
})
export class BillingModule {
  constructor(lux: Lux<{ billingState: IBmtBillingState }>) {
    const billingState = JSON.parse(sessionStorage.getItem('ma-billing-state'));
    lux.register('billingState', billingState ? billingState : initialStateMaBillingState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('ma-billing-state', JSON.stringify(state.billingState)));
  }
}
