import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { IContractReqObj } from './state';

@Injectable({
  providedIn: 'root'
})
export class MassActionsApiService {

  constructor(private http: HttpClient, private config: AppConfig) { }
  private handleResponse(r) { return r; }

  massTerminateOrReinstate(req, action) {
    req.applicationName = this.config.appName;
    req.sessionId = sessionStorage.getItem('sessionId');
    return this.http.post<any>(action === 'Reinstate' ? this.config.massReinstateUrl : this.config.massTerminateUrl, req);
  }
  massTransfer(req) {
    req.applicationName = this.config.appName;
    req.sessionId = sessionStorage.getItem('sessionId');
    return this.http.post<any>(this.config.massTransferUrl, req);
  }

  getContractInfo(req: IContractReqObj) {
    return this.http.post<any>(this.config.memberPlanSearchUrl, req);
  }
}
