import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppConfig } from '../../shared/app.config';
import { isUserName, isValidPassword, matchPasswords } from '../../utils/validations/field-rules';

@Component({
  selector: 'app-credentials',
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {

  constructor(private fb: UntypedFormBuilder, private config: AppConfig) {
  }

  credentialsForm: UntypedFormGroup;
  @Output() credentialsSubmit: EventEmitter<any> = new EventEmitter();
  securityQuestions: string[];

  ngOnInit(): void {
    this.securityQuestions = this.config.securityQuestions;
    this.credentialsForm = this.fb.group({
      userIdentifier: ['', [isUserName, Validators.required]],
      password: ['', [isValidPassword, Validators.required]],
      confirmPassword: ['', [matchPasswords, Validators.required]]
    });
  }

  filterQuestions(): void {
    this.securityQuestions = this.config.securityQuestions.filter(y => y !== this.credentialsForm.controls.securityQuestion1.value &&
      y !== this.credentialsForm.controls.securityQuestion2.value && y !== this.credentialsForm.controls.securityQuestion3.value);
  }
}
