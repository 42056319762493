import { IAdhocReportReq, ICustomGroupReportReq, ICustomGroupReportRes, IMassActionsReports, INotices, IOverageDepNoticeReq, IReportsState } from './state';

export const getMassActionsReport = (state: { reportsState: IReportsState }): IMassActionsReports => state.reportsState.massActionsReport;

export const getODNoticeReq = (state: { reportsState: IReportsState }): IOverageDepNoticeReq => state.reportsState.overageDepNoticeReq;

export const getAdhocReportReq = (state: { reportsState: IReportsState }): IAdhocReportReq => state.reportsState.adhocReportReq;

export const getODNoticeRes = (state: { reportsState: IReportsState }): INotices[] => state.reportsState.overageDepNoticeRes.notices;

export const isFetchingODReport = (state: { reportsState: IReportsState }): boolean =>
  state.reportsState.isFetchingAdhocReport ||
  state.reportsState.isFetchingOverageDepNotice ||
  state.reportsState.isFetchingDocument ||
  state.reportsState.isFetchingConsolidatedReport;

export const odErrors = (state: { reportsState: IReportsState }): string =>
  state.reportsState.adhocReportError ||
  state.reportsState.overageDepNoticeError ||
  state.reportsState.consolidatedReportErrors;

export const getCustomGroupReportRes = (state: { reportsState: IReportsState }): ICustomGroupReportRes => state.reportsState.customGroupReportRes;

export const getCustomGroupReportReq = (state: { reportsState: IReportsState }): ICustomGroupReportReq => state.reportsState.customGroupReportReq;

export const isFetchingCustomGroupReport = (state: { reportsState: IReportsState }): boolean => state.reportsState.isFetchingCustomGroupReport || state.reportsState.isFetchingDocument;

export const errorGetCustomGroupReport = (state: { reportsState: IReportsState }): string => state.reportsState.customGroupReportError;

export const errorGetDocument = (state: { reportsState: IReportsState }): string => state.reportsState.errorFetchingDocument;
