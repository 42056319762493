import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import {
  errorFetchingAuthsToEdit,
  errorsInUpdatingAuths,
  getAdminAndUserAuthsResposne,
  getManageUserAuthsResp,
  isFetchingAuthsToEdit,
  updatingAuthInProgress
} from 'src/app/store/createManageUser/selector';
import {
  IAdminAndUserAuthResponse,
  IAdminAndUserAuthsReq,
  IAdminClients,
  ICreateManageUserState,
  IManageuser,
  IManageUserAuthsReq,
  initialCreateManageUserState
} from 'src/app/store/createManageUser/state';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'bmt-manage-auth-at-group',
  templateUrl: './at-group-level.component.html',
  styleUrls: ['./at-group-level.component.scss']
})
export class ManageAuthAtGroupComponent implements OnInit, OnDestroy {
  // @Input() userContext: string;
  // @Input() selectedUserId: string;
  // @Input() adminRole: string;
  @Input() userInfo: IManageuser;
  @Output() cancelGroupAuthEdit: EventEmitter<boolean> = new EventEmitter();
  @Output() viewAuthorizations: EventEmitter<boolean> = new EventEmitter();


  luxId = 'BMT-edit-manage-auth-at-group' + this.constructor.name + new Date().toISOString();

  getErrors$: Observable<string> = this.lux.get(errorFetchingAuthsToEdit, this.luxId);
  isFetchingAuthsToEdit$: Observable<boolean> = this.lux.get(isFetchingAuthsToEdit, this.luxId);
  getUserAuthsToEdit$: Observable<IAdminAndUserAuthResponse> = this.lux.get(getAdminAndUserAuthsResposne, this.luxId);
  errorsInUpdatingAuths$: Observable<string> = this.lux.get(errorsInUpdatingAuths, this.luxId);
  updatingAuthInProgress$: Observable<boolean> = this.lux.get(updatingAuthInProgress, this.luxId);
  updatingAuthsDone$: Observable<string> = this.lux.get(getManageUserAuthsResp, this.luxId);

  totalAuths: number = 0;
  // currentPage = 1;
  defaultPageSize = 100;
  selectedIndex = 0;
  sortField: string = '';
  sortOrder: string = 'ASC';
  editAuthorizations = false;
  errors: string;
  fetchingAuths: boolean;
  userAndAdminAuths: IAdminAndUserAuthResponse;
  adminClientsInfo: IAdminClients[];
  selectedOption = '';
  updatingAuths: boolean;
  updatingAuthsResp: string;
  updatingAutherrors: string;
  initialSnapshotAllSubGroups: string;
  editAllSubGroupsForm: UntypedFormGroup;
  eligAccessLevelOptions = [{
    label: 'Update',
    value: 'Update'
  }, {
    label: 'Inquiry',
    value: 'Inquiry'
  }, {
    label: 'None',
    value: 'None'
  }];
  satAccessLevelOptions = [{
    label: 'Update',
    value: 'Update'
  }, {
    label: 'Inquiry',
    value: 'Inquiry'
  }, {
    label: 'None',
    value: 'None'
  }];

  inquiryOptions = [{
    label: 'Inquiry',
    value: 'Inquiry'
  }, {
    label: 'None',
    value: 'None'
  }];

  noneOptions = [{
    label: 'None',
    value: 'None'
  }];

  updateOptions = [{
    label: 'Update',
    value: 'Update'
  }, {
    label: 'Inquiry',
    value: 'Inquiry'
  }, {
    label: 'None',
    value: 'None'
  }];


  forNewlyCreatedUser = [{
    eligibilityAccessLevel: '',
    clientKnowledgeIndicator: false,
    billingReportAccessIndicator: false,
    customClientReportAccessIndicator: false,
    claimDetailReportAccessIndicator: false,
    satAccessLevel: '',
    satAccessReportIndicator: false
  }];
  authsModifiedForAllSubGroups: boolean;
  initailAdminUserAuthResponse = initialCreateManageUserState.adminAndUserAuths;
  snapshotAllOfCustomSubGroupForms: string;
  formChangesDetecedForCustomEdit: boolean;
  adminSubGroupAuths: {};
  singleClientOrSubGroupAdmins = ['BMT-CLIENT-ASSOCIATION-ADMIN', 'BMT-CLIENT-ASSOCIATION-DELEGATE', 'BMT-GROUP-ADMIN', 'BMT-SUBGROUP-ADMIN', 'BMT-GROUP-DELEGATE', 'BMT-SUBGROUP-DELEGATE'];
  displayConvertToUserCheck: boolean;
  isASingleClientAdmin: boolean;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private lux: Lux<{ createManageUserState: ICreateManageUserState }>,
              private createManageUserAsync: CreateManageUserAsync) {
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
    this.lux.set(state => state.createManageUserState.manageUserAuths, { authResponse: '', errors: '' });
    this.lux.set(state => state.createManageUserState.adminAndUserAuths, {
      authResponse: this.initailAdminUserAuthResponse.authResponse,
      errors: ''
    });
  }

  ngOnInit(): void {
    // console.log(!!(this.selectedOption && this.updatingAuthsResp && !this.updatingAuths));
    this.isASingleClientAdmin = ['BMT-GROUP-ADMIN', 'BMT-SUBGROUP-ADMIN'].includes(this.userInfo.adminRole);
    this.displayConvertToUserCheck = this.isASingleClientAdmin && this.userInfo.selectedUserType.toLowerCase() === 'delegate';
    this.errorsInUpdatingAuths$.subscribe(errors => this.updatingAutherrors = errors);
    this.updatingAuthInProgress$.subscribe(inprogress => this.updatingAuths = inprogress);
    this.updatingAuthsDone$.subscribe(res => {
      this.updatingAuthsResp = res;
      if (res) {
        // this.fetchUserAuthorizations(this.currentPage > 1 ? (this.currentPage-1)*this.defaultPageSize : 0);
        this.formChangesDetecedForCustomEdit = false;
        this.displayConvertToUserCheck = this.isASingleClientAdmin && (this.selectedOption === 'delegate');
        this.lux.set(state => state.createManageUserState.manageUser, { selectedUserType: (this.userInfo.selectedUserType.toLocaleLowerCase() !== 'delegate' && this.selectedOption === 'delegate') ? 'Delegate' : 'User' });
      }
      // Commenting out the auto close

      // if(this.selectedOption === 'customizeClientSubgroups') {
      //   setTimeout(() => {
      //     this.updatingAuthsResp = '';
      //   }, 5000);
      // }
    });

    this.getErrors$.subscribe(err => this.errors = err);
    this.isFetchingAuthsToEdit$.subscribe(fetchingAuths => this.fetchingAuths = fetchingAuths);
    this.getUserAuthsToEdit$.subscribe((adminAndUserAuths: IAdminAndUserAuthResponse) => {
      this.userAndAdminAuths = adminAndUserAuths;
      this.eligAccessLevelOptions = adminAndUserAuths.adminRelatedAuthorizations.eligibilityAccessLevel === 'Inquiry' ? this.eligAccessLevelOptions.filter(option => option.value !== 'Update') : adminAndUserAuths.adminRelatedAuthorizations.eligibilityAccessLevel === 'None' ? this.eligAccessLevelOptions.filter(option => option.value === 'None') : this.eligAccessLevelOptions;
      this.satAccessLevelOptions = adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel && adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel === 'Inquiry' ? this.satAccessLevelOptions.filter(option => option.value !== 'Update') : adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel && adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel === 'None' ? this.satAccessLevelOptions.filter(option => option.value === 'None') : this.satAccessLevelOptions;

      if (!adminAndUserAuths.userRelatedAuthorizations || (adminAndUserAuths.userRelatedAuthorizations && !adminAndUserAuths.userRelatedAuthorizations.length)) {
        adminAndUserAuths['userRelatedAuthorizations'] = this.forNewlyCreatedUser;
      }
      if (adminAndUserAuths.userRelatedAuthorizations.length) {
        this.selectedOption === 'clientAllSubgroups' && this.createEditAllSubGorupsForm();
        (this.selectedOption === 'customizeClientSubgroups' && adminAndUserAuths.userRelatedAuthorizations.filter(auths => auths.subGroupSpecifiedIdentifier).length) && this.createCustomAuthsForSubgroups();
      }
      this.totalAuths = adminAndUserAuths.pagination ? adminAndUserAuths.pagination.totalRecords : 0;
    });
  }

  fetchUserAuthorizations(offset) {
    const userContextStr = this.userInfo.contextOfAdmin.split('/');
    const adminAndUserAuthReq: IAdminAndUserAuthsReq = {
      userIdentifier: this.userInfo.selectedUserId,
      payer: userContextStr[1],
      payerAdmin: userContextStr[0],
      groupAccessLevel: (this.selectedOption === 'clientAllSubgroups') ? 'all' : 'one',
      groupSpecifiedIdentifier: userContextStr[2],
      ...userContextStr[3] && { subGroupSpecifiedIdentifier: userContextStr[3] },
      pagination: {
        offset: offset > this.totalAuths ? (offset - this.totalAuths) : offset,
        limit: this.defaultPageSize
      },
      sort: [
        {
          fieldName: 'specifiedId',
          order: 'ASC'
        }
      ]
    };
    this.lux.set(state => state.createManageUserState.adminAndUserAuths, { authRequest: adminAndUserAuthReq });
    this.createManageUserAsync.getAdminAndUserAuths('edit').subscribe();
  }

  getMoreAuths(page) {
    // this.currentPage = page.page;
    this.fetchUserAuthorizations(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize) : 0);
  }

  manageUserAuthFor(option) {
    this.selectedOption = option;
    (option !== 'revokeAtClient' && option !== 'delegate') && this.fetchUserAuthorizations(0);
  }

  manageAuthorizations() {
    this.userInfo.adminRole;
    const userContextStr = this.userInfo.contextOfAdmin.split('/');
    const manageUserReq: IManageUserAuthsReq = {
      userIdentifier: this.userInfo.selectedUserId,
      userType: this.selectedOption !== 'delegate' ? 'user' : 'delegate'
    };
    if (this.selectedOption !== 'delegate') {
      const authFormValues: any = this.selectedOption !== 'revokeAtClient' ? (this.selectedOption !== 'customizeClientSubgroups') ? (this.editAllSubGroupsForm.controls.userAuthForm as UntypedFormArray).getRawValue() : (this.editAllSubGroupsForm.controls.userAuthForm as UntypedFormGroup).getRawValue() : null;
      const dataToCrossCheck = this.selectedOption === 'customizeClientSubgroups' ? JSON.parse(this.snapshotAllOfCustomSubGroupForms) : {};

      manageUserReq.authorizations = [
        {
          payers: [userContextStr[1]],
          groupAccessLevel: 'one',
          groupSpecifiedIdentifier: userContextStr[2],
          ...(this.selectedOption === 'revokeAtClient') && { subgroupAccessLevel: 'none' },
          ...(this.selectedOption === 'clientAllSubgroups') && {
            subgroupAccessLevel: 'all',
            subgroupAuthorizations: [
              {
                eligibilityAccessLevel: authFormValues.userEligAccess,
                satAccessLevel: authFormValues.userSatAccess,
                clientKnowledgeIndicator: authFormValues.userClientKnowledge,
                customClientReportAccessIndicator: authFormValues.userCustomClientReportAccess,
                billingReportAccessIndicator: authFormValues.userBillingReport,
                claimDetailReportAccessIndicator: authFormValues.userClientDetailReport
              }
            ]
          },
          ...(this.selectedOption === 'customizeClientSubgroups') && {
            subgroupAccessLevel: 'custom',
            subgroupAuthorizations: authFormValues.map((subGrpFrmVal, index) => {
              const authObj = {
                subgroupSpecifiedIdentifier: subGrpFrmVal.id,
                eligibilityAccessLevel: subGrpFrmVal.userPrivilege ? subGrpFrmVal.userEligAccess : 'None',
                satAccessLevel: subGrpFrmVal.userPrivilege ? subGrpFrmVal.userSatAccess : 'None',
                customClientReportAccessIndicator: subGrpFrmVal.userPrivilege ? subGrpFrmVal.userCustomClientReportAccess : false,
                clientKnowledgeIndicator: subGrpFrmVal.userPrivilege ? subGrpFrmVal.userClientKnowledge : false,
                billingReportAccessIndicator: subGrpFrmVal.userPrivilege ? subGrpFrmVal.userBillingReport : false,
                claimDetailReportAccessIndicator: subGrpFrmVal.userPrivilege ? subGrpFrmVal.userClientDetailReport : false
              };
              // Changes to submit are only the changes on the selected subgroup or if the subgroup in deselected (added a condition to collect changes when converting to user)
              const changesToConsider = (this.userInfo.selectedUserType.toLowerCase() === 'delegate') ? authObj : (((dataToCrossCheck[index].userPrivilege !== subGrpFrmVal.userPrivilege) || subGrpFrmVal.userPrivilege) && (JSON.stringify(dataToCrossCheck[index]) !== JSON.stringify(subGrpFrmVal))) ? authObj : null;
              return changesToConsider;
            }).filter(val => val !== null)
          }
        }
      ];
    }
    this.triggerManageUserAuth(manageUserReq);
  }

  triggerManageUserAuth(manageUserReq: IManageUserAuthsReq) {
    this.lux.set(state => state.createManageUserState.manageUserAuths, { authRequest: manageUserReq });
    this.createManageUserAsync.manageUserAuthorizations().subscribe();
  }

  navigateToUserAdmin(action) {
    if (action === 'viewAuth') {
      this.viewAuthorizations.emit(true);
    }
    if (action === 'close') {
      this.cancelGroupAuthEdit.emit(true);
      this.updatingAuthsResp = '';
    }
    if (action === 'manage') {
      this.router.navigate(['userAdmin/manage']);
    }
    // this.updatingAuthsResp = '';
  }

  createEditAllSubGorupsForm() {
    const adminAuths = this.userAndAdminAuths.adminRelatedAuthorizations;
    const userAuths = this.userAndAdminAuths.userRelatedAuthorizations[0];
    this.editAllSubGroupsForm = this.fb.group({
      // adminEligAccess: adminAuths.eligibilityAccessLevel,
      adminEligAccess: '',
      adminClientKnowledge: false,
      adminCustomClientReport: false,
      adminBillingReport: false,
      adminClientDetailReport: false,
      // adminSatAccess: adminAuths.satAccessLevel || 'None',
      adminSatAccess: '',
      userAuthForm: this.fb.group({
        ...adminAuths.eligibilityAccessLevel && { userEligAccess: userAuths.eligibilityAccessLevel || 'None' },
        ...adminAuths.clientKnowledgeIndicator && { userClientKnowledge: userAuths.clientKnowledgeIndicator || false },
        ...adminAuths.customClientReportAccessIndicator && { userCustomClientReportAccess: userAuths.customClientReportAccessIndicator || false },
        ...adminAuths.billingReportAccessIndicator && { userBillingReport: userAuths.billingReportAccessIndicator || false },
        ...adminAuths.claimDetailReportAccessIndicator && { userClientDetailReport: userAuths.claimDetailReportAccessIndicator || false },
        ...adminAuths.satAccessLevel && { userSatAccess: userAuths.satAccessLevel || 'None' }
      })
    });
    const editFromContrls = this.editAllSubGroupsForm.controls;
    const userAuthForm = this.editAllSubGroupsForm.controls.userAuthForm as UntypedFormGroup;
    const userAuthFormContrls = this.editAllSubGroupsForm.controls.userAuthForm['controls'];
    this.initialSnapshotAllSubGroups = JSON.stringify(userAuthForm.getRawValue());
    editFromContrls.adminEligAccess.valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe(x => {
      userAuthFormContrls.userEligAccess.setValue(x);
    });
    editFromContrls.adminClientKnowledge.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userClientKnowledge.setValue(x);
    });
    editFromContrls.adminCustomClientReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userCustomClientReportAccess.setValue(x);
    });
    editFromContrls.adminBillingReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userBillingReport.setValue(x);
    });
    editFromContrls.adminClientDetailReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userClientDetailReport.setValue(x);
    });
    editFromContrls.adminSatAccess.valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe(x => {
      userAuthFormContrls.userSatAccess.setValue(x);
    });
    userAuthForm.valueChanges.pipe(startWith(userAuthForm.getRawValue())).subscribe(val => {
      this.authsModifiedForAllSubGroups = (this.userInfo.selectedUserType.toLowerCase() === 'delegate') || (this.initialSnapshotAllSubGroups !== JSON.stringify(userAuthForm.getRawValue()));
    });
  }

  createCustomAuthsForSubgroups() {
    const adminAuths = this.userAndAdminAuths.adminRelatedAuthorizations;
    const userAuths = this.userAndAdminAuths.userRelatedAuthorizations;
    if (this.selectedOption === 'customizeClientSubgroups') {
      const objectToConsider = !this.singleClientOrSubGroupAdmins.includes(this.userInfo.adminRole) ? adminAuths.subGroupAuthorizations : userAuths;
      this.adminSubGroupAuths = this.createAdminSubGrpAuthsObj(objectToConsider);
    }
    if (Object.keys(this.adminSubGroupAuths).length) {
      this.editAllSubGroupsForm = this.fb.group({
        selectAllSubGroups: false,
        adminEligAccess: '',
        // adminEligAccess: adminAuths.eligibilityAccessLevel,
        adminClientKnowledge: false,
        adminCustomClientReport: false,
        adminBillingReport: false,
        adminClientDetailReport: false,
        // adminSatAccess: adminAuths.satAccessLevel || 'None',
        adminSatAccess: '',
        userAuthForm: userAuths.length ? new UntypedFormArray(userAuths.map(subgroup => {
          const group = this.fb.group({
            id: subgroup.subGroupSpecifiedIdentifier,
            userPrivilege: subgroup.userPrivilegeSelected,
            ...this.adminSubGroupAuths[subgroup.subGroupSpecifiedIdentifier].eligibilityAccessLevel && { userEligAccess: subgroup.eligibilityAccessLevel || 'None' },
            ...this.adminSubGroupAuths[subgroup.subGroupSpecifiedIdentifier].customClientReportAccessIndicator && { userCustomClientReportAccess: subgroup.customClientReportAccessIndicator || false },
            ...this.adminSubGroupAuths[subgroup.subGroupSpecifiedIdentifier].clientKnowledgeIndicator && { userClientKnowledge: subgroup.clientKnowledgeIndicator || false },
            ...this.adminSubGroupAuths[subgroup.subGroupSpecifiedIdentifier].billingReportAccessIndicator && { userBillingReport: subgroup.billingReportAccessIndicator || false },
            ...this.adminSubGroupAuths[subgroup.subGroupSpecifiedIdentifier].claimDetailReportAccessIndicator && { userClientDetailReport: subgroup.claimDetailReportAccessIndicator || false },
            ...this.adminSubGroupAuths[subgroup.subGroupSpecifiedIdentifier].satAccessLevel && { userSatAccess: subgroup.satAccessLevel || 'None' }
          });
          return group;
        })) : '',
      });
      const editFromContrls = this.editAllSubGroupsForm.controls;

      const userAuthForm = editFromContrls.userAuthForm as UntypedFormArray;
      const subGroupForms = userAuthForm.controls;
      this.snapshotAllOfCustomSubGroupForms = JSON.stringify(userAuthForm.getRawValue());
      editFromContrls.selectAllSubGroups.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userPrivilege && form.controls.userPrivilege.setValue(x);
        });
      });
      editFromContrls.adminEligAccess.valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userEligAccess && form.controls.userEligAccess.setValue(x);
        });
      });
      editFromContrls.adminCustomClientReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userCustomClientReportAccess && form.controls.userCustomClientReportAccess.setValue(x);
        });
      });
      editFromContrls.adminClientKnowledge.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userClientKnowledge && form.controls.userClientKnowledge.setValue(x);
        });
      });
      editFromContrls.adminBillingReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userBillingReport && form.controls.userBillingReport.setValue(x);
        });
      });
      editFromContrls.adminClientDetailReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userClientDetailReport && form.controls.userClientDetailReport.setValue(x);
        });
      });
      editFromContrls.adminSatAccess.valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe(x => {
        subGroupForms.map((form: UntypedFormGroup) => {
          form.controls.userSatAccess && form.controls.userSatAccess.setValue(x);
        });
      });
      userAuthForm.valueChanges.pipe(distinctUntilChanged(), startWith(userAuthForm.getRawValue())).subscribe(val => {
        // const wereThereAnySubsSelected = userAuthForm.getRawValue().filter(selectedSubGrp => selectedSubGrp.userPrivilege);
        // this.formChangesDetecedForCustomEdit = wereThereAnySubsSelected.length && (this.snapshotAllOfCustomSubGroupForms !== JSON.stringify(userAuthForm.getRawValue()));
        this.formChangesDetecedForCustomEdit = (this.userInfo.selectedUserType.toLowerCase() === 'delegate') || (this.snapshotAllOfCustomSubGroupForms !== JSON.stringify(userAuthForm.getRawValue()));
      });
    }
  }

  createAdminSubGrpAuthsObj(authsToConsider) {
    const authAtSubGroups = authsToConsider.length ? authsToConsider.reduce((bag, key) => ({
      ...bag,
      [key.subGroupSpecifiedIdentifier]: !this.singleClientOrSubGroupAdmins.includes(this.userInfo.adminRole) ? key : this.userAndAdminAuths.adminRelatedAuthorizations
    }), {}) : {};
    return authAtSubGroups;
  }

}
