import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginAsyncService } from '../login/store/login-async.service';
import { LoginApiService } from '../login/store/login-api.service';
import { AuthorizationsService } from '../authorizations/authorizations.service';
import { IAuthInfo, ILoginState, ISolutionsState, IUserAuthorizations, IValueFormat } from '../login/store/state';
import { AppConfig } from '../shared/app.config';
import { UserAuthConfig as EligUserAuth } from '@roosevelt/elig-ui-lib';
import { UserAuthConfig as ClientUserAuth } from '@roosevelt/client-ui-lib';
import { UserAuthConfig as BillingUserAuth } from '@roosevelt/billing-lib';
import { AlertsAsync } from '../store/alerts/async';
import { Observable, Subscription, TimeoutError } from 'rxjs';
import { CreateManageUserAsync } from '../store/createManageUser/async';
import { IAdminClients, ICreateManageUserState } from '../store/createManageUser/state';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';
import { AuthorizationsAsync } from 'src/app/authorizations/authorizations-async';
import { getRelaxedUiAuth } from 'src/app/authorizations/selector';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonUserService, Lux } from '@roosevelt/common-ui-lib/core';
import { NotificationService } from '../shared/services/notification.service';

@Component({
  selector: 'app-oidc-callback',
  templateUrl: './oidc-callback.component.html',
  styleUrls: ['./oidc-callback.component.scss']
})
export class OidcCallbackComponent implements OnInit {
  luxId = 'BMT-oidcComponent' + new Date().toISOString();
  checkForPlan: any;
  bmtAlertsAsyncSub$: Subscription;
  userId: string;
  isUserBeingLoggedIn: boolean;
  userRoleError = '';
  postLogoutRedirectUrl: string;
  authInfo: IAuthInfo;
  impersonationUser: string;
  tokenInfo: any;
  loggedInUserRole: any;
  constructor(
    private lux: Lux<{
      solutionsLogin: ISolutionsState,
      login: ILoginState,
      createManageUserState: ICreateManageUserState, appUiAuth: IAppAuthorizations
    }>,
    private router: Router,
    private route: ActivatedRoute,
    private loginAsync: LoginAsyncService,
    private authService: AuthorizationsService,
    private loginApiService: LoginApiService,
    private env: AppConfig,
    @Inject(EligUserAuth) private eligUserAuth: EligUserAuth,
    @Inject(ClientUserAuth) private clientUserAuth: ClientUserAuth,
    @Inject(BillingUserAuth) private billingUserAuth: BillingUserAuth,
    private bmtAlertsAsync: AlertsAsync,
    private userRoleService: CreateManageUserAsync,
    private uiAuthAsync: AuthorizationsAsync,
    private commonUserService: CommonUserService,
    private notificationService: NotificationService
  ) {
  }

  routesMapping = {
    manageeligibility: '/eligibility/member',
    benefitsInquiry: '/benefits',
    reports: '/reports',
    billing: '/billing',
    summaryOfChanges: '/summary-of-changes',
    dentistDirectory: '/dentist',
    sat: '/sat',
    userAdmin: '/userAdmin',
    profile: '/profile/authorizations'
  };
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);
  leftNavFeatures: string[] = [];

  ngOnInit(): void {
    // this.isUserBeingLoggedIn = true;
    this.checkForPlan = this.env.plansAllowed;
    let hasIdToken = false;
    const hashParts = location.hash.substring(1)
      .split('&')
      .map(part => part.split(/=(.+)/));

    for (const param of hashParts) {
      sessionStorage.setItem(param[0], param[1]);
      if (param[0].indexOf('id_token') !== -1 || param[0].indexOf('access_token') !== -1) {
        hasIdToken = true;
      }
      if (param[0].indexOf('for_user') !== -1) {
        this.impersonationUser = param[1];
      }
    }
    let acc_token = '';
    const oktaSession = sessionStorage.getItem('okta-token-storage');
    if (oktaSession?.indexOf('idToken') > -1 || oktaSession?.indexOf('accessToken') > -1) {
      hasIdToken = true;
      acc_token = JSON.parse(oktaSession).accessToken.accessToken;
    }

    if (oktaSession?.indexOf('accessToken') > -1) {
      acc_token = JSON.parse(oktaSession).accessToken.accessToken;
    }
    const idToken = sessionStorage.getItem('impersonate_id_token') || sessionStorage.getItem('impersonate_access_token') || acc_token || sessionStorage.getItem('id_token') as string;
    this.tokenInfo = JSON.parse(atob(idToken.split('.')[1]));

    if (!hasIdToken) {
      this.lux.set(state => state.solutionsLogin, {
        isLoginFailed: true
      });
      sessionStorage.clear();
      this.router.navigate(['/login']);
      throw Error();
    }
    // this.isFetchingContext$.subscribe(progress => this.isFetchingContext = progress);
    this.login();
  }

  login() {
    // if (this.env.secureAuth.use) {
    //   this.loginAsync.getAuthInfo().subscribe((response) => {
    //     sessionStorage.setItem('auth-user-info', JSON.stringify(response.body));
    //     this.userId = response.body.id;
    //     sessionStorage.setItem('csrf', response.headers.get('X_CSRF_TOKEN'));
    //     localStorage.setItem('BMT-csrf', response.headers.get('X_CSRF_TOKEN'));
    //   });
    // }
    this.authInfo = {
      email: this.commonUserService.claims?.email || this.tokenInfo?.email,
      firstName: this.commonUserService.claims?.firstName,
      lastName: this.commonUserService.claims?.lastName,
      id: this.commonUserService.claims?.id || this.tokenInfo?.sub,
      expirationTime: this.tokenInfo?.exp,
      cid: this.tokenInfo?.cid,
      impersonationUser: this.impersonationUser || ''
    };
    sessionStorage.setItem('auth-user-info', JSON.stringify(this.authInfo));
    this.lux.set(state => state.login, { authInfo: this.authInfo });
    this.userId = this.commonUserService.claims?.id || this.tokenInfo.sub;
    this.getSessionId();
    this.getUserAuthrizations();
  }

  getSessionId() {
    const session = sessionStorage.getItem('sessionId');
    if (!session) {
      this.loginApiService.getSessionId().subscribe((res: any) => {
        let errorMessage;
        if (res instanceof HttpErrorResponse) {
          errorMessage = (res &&
            res.error &&
            res.error.apiErrorList &&
            res.error.apiErrorList.length > 0) && (errorMessage = res.error.apiErrorList.map(item => item.errorMessage).join('\n'));
          this.noAuthorizations(false, errorMessage);
        } else if (res instanceof TimeoutError) {
          this.noAuthorizations(false, 'Session Id API timed out');
        } else {
          sessionStorage.setItem('sessionId', res);
        }
      });
    }
  }

  getUserAuthrizations() {
    let plansOptions: IValueFormat[];
    let buildAdminPlan: IUserAuthorizations = {
      userId: this.userId,
      userAdminPlansAndPlans: {},
      roles: [],
      resources: []
    };

    // API call to fetch the admin roles
    this.userRoleService.getLoggedInUserRole().subscribe((adminRolesResponse: any) => {
      const anyErrorsFetchingRoles = (adminRolesResponse && adminRolesResponse.error) || adminRolesResponse.name;
      if (adminRolesResponse instanceof HttpErrorResponse &&
        adminRolesResponse.error &&
        adminRolesResponse.error.apiErrorList &&
        adminRolesResponse.error.apiErrorList.length > 0
      ) {
        adminRolesResponse.error.apiErrorList.forEach(item => {
          this.userRoleError += item.errorMessage + '\n';
        });
      } else if (adminRolesResponse instanceof TimeoutError) {
        this.noAuthorizations(false, 'Fetching role timed out');
      } else {
        this.userRoleError = adminRolesResponse.error;
      }
      // API call to fetch the context admin/plan/group/subgroup
      if (!anyErrorsFetchingRoles) {
        this.loggedInUserRole = adminRolesResponse;
        const singleClientOrSubGroupAdmins = ['BMT-GROUP-ADMIN', 'BMT-SUBGROUP-ADMIN', 'BMT-GROUP-DELEGATE', 'BMT-SUBGROUP-DELEGATE'];
        const groupOrSubgroupAdmin = singleClientOrSubGroupAdmins.includes(adminRolesResponse.users[0].roleName);
        // API call to fetch the admin plans context
        this.userRoleService.getLoggedInUserContext(groupOrSubgroupAdmin ? 3 : 2, groupOrSubgroupAdmin).subscribe((adminClients: IAdminClients[]) => {
          adminClients = adminClients.filter(adminPlns => adminPlns.PLAN && adminPlns.PLAN !== 'INVLDCTX');
          const userAdminPlansAndPlans = adminClients.reduce((r, a) => {
            r[a.ADMINPLAN] = [...r[a.ADMINPLAN] || [], a.PLAN];
            return r;
          }, {});
          this.lux.set(state => state.createManageUserState.manageUser, {
            planAcronyms: adminClients.map(adminPlan => adminPlan.PLAN)
          });
          if (Object.keys(userAdminPlansAndPlans).length) {
            buildAdminPlan = {
              ...buildAdminPlan,
              resources: [adminRolesResponse.users[0].roleName],
              userAdminPlansAndPlans
            };
            // Checking if the logged in admin is authorized to access BMT based on the URL he is hitting
            const adminPlans = Object.keys(buildAdminPlan.userAdminPlansAndPlans);
            const planFromUrl = document.location.pathname.split('/')[1];
            const checkIfPlanExists = (adminPlans.includes(this.checkForPlan[planFromUrl]) &&
              Object.keys(this.checkForPlan).includes(planFromUrl));
            if (checkIfPlanExists) {
              this.lux.set(state => state.login, {
                userAuthorizations: buildAdminPlan,
                ...(checkIfPlanExists) && {
                  selectedAdminPlan: this.checkForPlan[planFromUrl]
                }
              });
              this.eligUserAuth.userAuthorizations = this.clientUserAuth.userAuthorizations = this.billingUserAuth.userAuthorizations = buildAdminPlan.userAdminPlansAndPlans;
              const BMTAlertsReq = {
                'recipient': {
                  'allUsers': true,
                  'userNames': [this.userId],
                  'memberPlanAcronyms': buildAdminPlan.userAdminPlansAndPlans[this.checkForPlan[planFromUrl]]
                },
                'adminPlanAcronyms': [this.checkForPlan[planFromUrl]],
                'applicationTypeKey': 'BMT_DENTAL',
                'hideDismissedAlerts': false,
                'onlyActiveAlerts': true,
                'userName': this.userId
              };
              // Below API call is to fetch BMT alerts
              this.bmtAlertsAsyncSub$ = this.bmtAlertsAsync.getBMTAlerts(BMTAlertsReq).subscribe();
              // this.isUserBeingLoggedIn = false;
              // this.router.navigateByUrl('/eligibility/member');
              this.getUiAuthorizations(this.checkForPlan[planFromUrl]);
            } else {
              this.noAuthorizations();
            }
          } else {
            this.noAuthorizations();
          }
        });
      } else {
        this.noAuthorizations(anyErrorsFetchingRoles);
      }
    });
  }

  getUiAuthorizations(adminPlan) {
    this.uiAuthAsync.getAuthorizations('relaxed', adminPlan).subscribe((uiAuth: IUiAuthorizations) => {
      if (uiAuth) {
        this.setTheApp(uiAuth, adminPlan);
      }
    });
  }

  setTheApp(uiAuth: IUiAuthorizations, adminPlan) {
    // Check with where to route if 'manageEligibility' is not in authorizations.
    if (Object.keys(uiAuth).length) {
      this.leftNavFeatures = uiAuth.leftnav;
      const isEligInNavigation = this.leftNavFeatures && this.leftNavFeatures.length && this.leftNavFeatures.includes('manageeligibility');
      this.router.navigateByUrl(isEligInNavigation ? '/eligibility/member' : this.routesMapping[this.leftNavFeatures[0]]);
      if (adminPlan === 'DDPMI' && this.leftNavFeatures.includes('billing')) {
        this.getPayNotifications();
      }
    } else {
      this.noAuthorizations();
    }
  }

  getPayNotifications() {
    this.bmtAlertsAsync.getPaymentDueNotification().subscribe(success => {
      if (success) {
        this.notificationService.setNotificationState(true);
      }
    });
  }

  noAuthorizations(anyErrorsFetchingRoles?, timeOutError?) {
    if (timeOutError) {
      this.lux.set(state => state.solutionsLogin, {
        timeOutError: timeOutError
      });
    } else {
      this.lux.set(state => state.solutionsLogin, {
        ...!anyErrorsFetchingRoles && { noAuthroziations: true },
        ...anyErrorsFetchingRoles && { moreUserRoles: this.userRoleError }
      });
    }
    sessionStorage.clear();
    this.router.navigate(['/notAuthorized']);
  }

}
