import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { BmtbillingService } from './api.service';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IBmtBillingState } from './state';
import { getEbillRequest } from './selector';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class BmtBillingAsync {
  constructor(
    private lux: Lux<{ billingState: IBmtBillingState }>,
    private bmtbillingService: BmtbillingService,
  ) {
  }

  insertEbillContext(): Observable<any> {
    return this.lux.get(getEbillRequest).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.billingState, { isFetching: true })),
      mergeMap(x => this.bmtbillingService.insertEbillRecord(x)),
      tap(results => {
        this.lux.set(state => state.billingState, { isFetching: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(err => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.billingState, { ebillError: errorMessage, isFetching: false });
        return of(errorMessage);
      })
    );
  }

  registerAchPayment(registerAchPaymentRequest): Observable<any> {
    return of(registerAchPaymentRequest).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.billingState, { isFetching: true })),
      mergeMap(x => this.bmtbillingService.registerAchPayment(x)),
      tap(results => {
        this.lux.set(state => state.billingState, { isFetching: false });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.billingState, { achPaymentError: errorMessage, isFetching: false });
        return of(errorMessage);
      })
    );
  }

  registerAchPayDetails(registerAchPaymentRequest): Observable<any> {
    return of(registerAchPaymentRequest).pipe(
      first(),
      tap(() =>
        this.lux.set(state => state.billingState, { isFetchingAch: true, achPayError: '' })),
      mergeMap(x => this.bmtbillingService.registerAchPay(x)),
      tap(results => {
        this.lux.set(state => state.billingState, { isFetchingAch: false });
      }),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.billingState, { achPayError: errorMessage, isFetching: false });
        return of(errorMessage);
      })
    );
  }

  getStates(countryCode): Observable<any> {
    return this.bmtbillingService.getStatesList(countryCode);
  }

  getCountries(): Observable<any> {
    return this.bmtbillingService.getCountriesList();
  }
}
