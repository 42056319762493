import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class BmtbillingService {

  constructor(private http: HttpClient, private config: AppConfig, private internalAuth: BasicAuthConfig) {
  }

  insertEbillRecord(req) {
    return this.http.post<any>(this.config.eBillUrl, req, { observe: 'response' });
  }

  registerAchPayment(req) {
    return this.http.post<any>(this.config.achPaymentRegistrationUrl, req, { observe: 'response' });
  }

  registerAchPay(req) {
    return this.http.post<any>(this.config.achPayRegistrationUrl, req, { observe: 'response' });
  }

  getStatesList(countryCode) {
    this.internalAuth.authedUrls.push(
      this.config.getCountries + '/' + countryCode + '/states'
    );
    return this.http.get(
      this.config.getCountries + '/' + countryCode + '/states'
    );
  }

  getCountriesList() {
    return this.http.get(this.config.getCountries);
  }
}
