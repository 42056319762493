<ng-container [ngSwitch]="payer">
  <ng-container *ngSwitchCase="'DDAZ'">
    <ul>
      <li>Mail ACH agreement termination notice to:</li>
      <li>Delta Dental of Arizona</li>
      <li>Attn: Billing</li>
      <li>P.O. Box 43000</li>
      <li>Phoenix, AZ 85080-3000</li>
      <li>Should you have any questions regarding your Direct Debit (ACH) Instructions, please contact the Accounting</li>
      <li>Department at 1.602-588-3614 or at Billing&#64;deltadentalaz.com.</li>
      <li>Office Hours are Monday to Thursday 8am to 4:30 p.m. PST and Friday 8 a.m. to 4 p.m. PST.</li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="'DDKY'">
    <ul>
      <li>Mail ACH agreement termination notice to:</li>
      <li>Delta Dental of Kentucky</li>
      <li>Attn: Leanne Thornsberry</li>
      <li>P O Box 242810</li>
      <li>Louisville, KY 40224-2810</li>
      <li>Should you have any questions regarding your Direct Debit (ACH) Instructions, please contact the Accounting</li>
      <li>Department at 1-800-955-2030 or at groupservices&#64;deltadentalky.com.</li>
      <li>Office hours are Monday through Friday, 8am to 4pm.</li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="'DDPTN'">
    <ul>
      <li>Mail ACH agreement termination notice to:</li>
      <li>Delta Dental of Tennessee</li>
      <li>Attn: Finance</li>
      <li>240 Venture Circle</li>
      <li>Nashville, TN 37228</li>
      <li>Should you have any questions regarding your Direct Debit (ACH) Instructions, please contact the Accounting</li>
      <li>Department at 1-888-281-9396 Ext 26585  or at billing&#64;deltadentaltn.com.</li>
      <li>Office hours areMonday - Friday 7:00am – 4:00pm EST.</li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchCase="'DDMN'">
    <ul>
      <li>Mail ACH agreement termination notice to:</li>
      <li>500 Washington Avenue So, Suite 2060</li>
      <li> Minneapolis, MN 55415</li>
      <li>Should you have any questions regarding your Direct Debit (ACH) Instructions, please contact the Accounting</li>
      <li>Department at 1-800-906-4702  or at AR&#64;deltadentalmn.org.</li>
      <li>Office hours are Monday through Friday, 8 a.m. to 5 p.m. CST</li>
    </ul>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ul>
      <li>Mail ACH agreement termination notice to:</li>
      <li>Delta Dental</li>
      <li>Attn: Accounts Receivable</li>
      <li>P.O. Box 30416</li>
      <li>Lansing, MI 48909-7916</li>
      <li>Should you have any questions regarding your Direct Debit (ACH) Instructions, please contact the Accounting</li>
      <li>Department at 1.800.838.8863 or at billing&#64;mydeltadental.com.</li>
      <li>Office hours are Monday through Friday, 8 a.m. to 5 p.m. EST.</li>
    </ul>
  </ng-container>
</ng-container>
