import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  subHeader = 'Notice';
  chosenReportAction = 'Notice';
  reportFeatures = [];

  constructor() {
  }

  ngOnInit() {
  }

  toggle(event) {
    this.subHeader = event;
  }

  changeForm(selectedODCategory) {
    this.chosenReportAction = selectedODCategory;
    this.subHeader = selectedODCategory;
  }
}
