import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryOfChangesComponent } from './summary-of-changes.component';
import { SummaryOfChangesContainer } from './summary-of-changes.container';
import { ISummaryOfChangesState } from '../store/summaryOfChanges/state';
import { initialStateSummaryOfChange } from '../store/summaryOfChanges/initialState';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClientSearchModule } from '@roosevelt/client-ui-lib';
import { UserTransactionsComponent } from './user-transactions/user-transactions.component';
import { SummaryDetailsComponent } from './summary-details/summary-details.component';
import { SummaryDetailsContainer } from './summary-details/summary-details.container';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { Lux } from '@roosevelt/common-ui-lib/core';


@NgModule({
  declarations: [
    SummaryOfChangesContainer,
    SummaryOfChangesComponent,
    UserTransactionsComponent,
    SummaryDetailsComponent,
    SummaryDetailsContainer
  ],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    ReactiveFormsModule,
    ClientSearchModule,
    LoaderModule,
    PaginatorModule,
    FormsModule
  ]
})
export class SummaryOfChangesModule {
  constructor(lux: Lux<{ sChanges: ISummaryOfChangesState }>) {
    const scState = JSON.parse(sessionStorage.getItem('summary-of-changes-state'));
    lux.register('sChanges', scState ? scState : initialStateSummaryOfChange);
    lux.allChanges().subscribe(state => sessionStorage.setItem('summary-of-changes-state', JSON.stringify(state.sChanges)));
  }
}
