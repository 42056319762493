import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientknowledgeComponent } from './clientknowledge.component';
import { IClientKnowledgeState } from '../store/clientKnowledge/state';
import { clientKnowledgeInitialState } from '../store/clientKnowledge/initialState';
import { ClientKnowledgeContainer } from './clientknowledge.container';
import { Lux } from '@roosevelt/common-ui-lib/core';


@NgModule({
  declarations: [
    ClientknowledgeComponent, ClientKnowledgeContainer
  ],
  imports: [
    CommonModule
  ]
})
export class ClientknowledgeModule {
  constructor(lux: Lux<{ clientKnowledgeState: IClientKnowledgeState }>) {
    const clientKnowledgeState = JSON.parse(sessionStorage.getItem('bmt-client-knowledge-state'));
    lux.register('clientKnowledgeState', clientKnowledgeState ? clientKnowledgeState : clientKnowledgeInitialState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('bmt-client-knowledge-state', JSON.stringify(state.clientKnowledgeState)));
  }
}
