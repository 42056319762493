import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bmt-user-admin',
  templateUrl: './user-admin.component.html',
  styleUrls: ['./user-admin.component.scss']
})
export class UserAdminComponent implements OnInit {
  page: string = 'create';
  routeSub: Subscription;

  constructor(
    private activeRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.routeSub = this.activeRoute.params.subscribe(params => this.page = params.id);
  }

  toggle(page) {
    this.page = page;
  }

}
