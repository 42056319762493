import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({
  declarations: [],
  exports: [], imports: [BrowserModule], providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class SharedModule {
}
