
<!-- <div id="scrollId"></div> -->
<div class="multi-group-level">
    <rs-loader *ngIf="(updatingAuths && selectedOption !== 'customizeGroups') || (fetchingAuths && selectedOption === 'allGroups') || fetchingClients"></rs-loader>
    <p *ngIf="errors" class="error">{{errors}}</p>
    <p *ngIf="updatingAutherrors" class="error">Errors updating authorizations: {{updatingAutherrors}}</p>
    <div class="auth-options">
        <ng-container *ngIf="!alreadyUser">
            <rs-checkbox color="secondary" id="convertToUser" name="convertToUser" label="Convert to user" value="convertToUser" (change)="alreadyUser = true"></rs-checkbox>
        </ng-container>
        <rs-notification type="info" [visible]="selectedOption === 'allGroups'" message="Please keep in mind that the access noted below for ‘All groups’ may not be available for every group. For example, as the administrator, you may have access to Billing Reports for group A, but not for group B. In this case, selecting Billing Reports for all groups will not provide this user with Billing Reports for group B.  Your users will not receive a higher level of access than you when selecting this option."></rs-notification>
        <ng-container *ngIf="alreadyUser">
            <rs-radio id="radio1" name="multipleClients" value="allGroups" label="Select for all Groups" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
            <rs-radio id="radio2" name="multipleClients" value="customizeGroups" label="Select to customize Groups" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
            <rs-radio id="radio3" *ngIf="adminOnly.includes(userInfo.adminRole) && userInfo.selectedUserType.toLowerCase() !== 'delegate'" name="multipleClients" value="delegate" label="Create Delegate - a user who can create users and manage authorizations on your behalf" (change)="manageUserAuthFor($event.target.value)"></rs-radio>
        </ng-container>
    </div>
    <!-- Below is the holder for the all groups auth modifications -->
    <ng-container *ngIf="selectedOption && selectedOption === 'allGroups' && !errors && userAndAdminAuths.userRelatedAuthorizations.length && editAllGroupsForm">
        <form [formGroup]="editAllGroupsForm">
            <table>
                <thead>
                    <th>Group ID</th>
                    <th>Group Name</th>
                    <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel">
                        <span>Eligibility Access</span>
                        <rs-select id="adminEligAccess" formControlName="adminEligAccess" [items]="eligAccessLevelOptions"></rs-select>
                    </th>
                    <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">
                        <span>Client Knowledge</span>
                        <rs-checkbox id="adminClientKnowledge" formControlName="adminClientKnowledge"></rs-checkbox>
                    </th>
                    <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">
                      <span>Custom Client Reports</span>
                      <rs-checkbox id="adminCustomClientReport" formControlName="adminCustomClientReport"></rs-checkbox>
                    </th>
                    <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">
                        <span>Billing Report</span>
                        <rs-checkbox id="adminBillingReport" formControlName="adminBillingReport"></rs-checkbox>
                    </th>
                    <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">
                        <span>Claim Detail Report</span>
                        <rs-checkbox id="adminClientDetailReport" formControlName="adminClientDetailReport"></rs-checkbox>
                    </th>
                    <th *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">
                        <span>Sat Access Level</span>
                        <rs-select id="adminSatAccess" formControlName="adminSatAccess" [items]="satAccessLevelOptions"></rs-select>
                    </th>
                </thead>
                <tbody formGroupName="userAuthForm">
                    <tr *ngFor="let user of userAndAdminAuths.userRelatedAuthorizations">
                        <td>All</td>
                        <td>All Groups</td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.eligibilityAccessLevel">
                            <!-- {{user.eligibilityAccessLevel || 'None'}} -->
                            <rs-select formControlName="userEligAccess" [id]="user.subGroupSpecifiedIdentifier+'eligibilityAccessLevel'" [items]="eligAccessLevelOptions"></rs-select>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.clientKnowledgeIndicator">
                            <rs-checkbox formControlName="userClientKnowledge" [id]="user.subGroupSpecifiedIdentifier+'clientKnowledgeIndicator'"></rs-checkbox>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.customClientReportAccessIndicator">
                          <rs-checkbox formControlName="userCustomClientReportAccess" [id]="user.subGroupSpecifiedIdentifier+'customClientReportAccessIndicator'"></rs-checkbox>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.billingReportAccessIndicator">
                            <rs-checkbox formControlName="userBillingReport" [id]="user.subGroupSpecifiedIdentifier+'billingReportAccessIndicator'"></rs-checkbox>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.claimDetailReportAccessIndicator">
                            <rs-checkbox formControlName="userClientDetailReport" [id]="user.subGroupSpecifiedIdentifier+'claimDetailReportAccessIndicator'"></rs-checkbox>
                        </td>
                        <td *ngIf="userAndAdminAuths.adminRelatedAuthorizations.satAccessLevel">
                            <!-- {{user.satAccessLevel || 'None'}} -->
                            <rs-select formControlName="userSatAccess" [id]="user.subGroupSpecifiedIdentifier+'satAccessLevel'" [items]="satAccessLevelOptions"></rs-select>
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>
    </ng-container>
    <!-- Below is the container to populate the admin groups -->
    <div class="admin-clients-container" *ngIf="selectedOption === 'customizeGroups'">
        <ng-container *ngFor="let clientsInfo of adminClientsInfo">
            <ng-container *ngIf="clientsInfo.specifiedId">
                <p *ngIf="errorsFetchingClients" class="errors">Errors fetching clients: {{errorsFetchingClients}}</p>
                <div class="admin-clients">
                    <div class="user-context" (click)="toggleTheClient(clientsInfo)">
                        <div class="context">
                            <span>Payer:</span>{{clientsInfo.planAcronym}}
                            <span>Group Name:</span>{{clientsInfo.name}}
                            <span>Group Id:</span>{{clientsInfo.specifiedId}}
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
    <rs-paginator *ngIf="selectedOption === 'customizeGroups'" [length]="totalRecords"
                (page)="getMoreClients($event)" showFirstLastButtons
                [pageSize]="defaultPageSize"></rs-paginator>
    <!-- Save and Cancel buttons container -->
    <div class="btns-cont">
        <ng-container *ngIf="selectedOption && selectedOption !== 'customizeGroups'">
            <button rs-raised-button class="next-btn" [disabled]="alreadyUser && selectedOption === 'allGroups' && !authsModifiedForAllGroups" color="primary" (click)="manageAuthorizations()">Save</button>
        </ng-container>
        <button rs-raised-button color="secondary" (click)="cancelGroupAuthEdit.emit(true)">Cancel</button>
    </div>
    <!-- Pop up to open the selected group auth options -->
    <ng-container *ngIf="selectedClient && selectedClient.specifiedId">
        <rs-dialog visible="true" heading="Choose authorizations for the group" maxWidth="80%" class="group-auth-options" (visibleChange)="selectedClient = null">
            <div class="group-auth-options-content">
                <div class="context">
                    <span>Admin Payer:</span>{{userInfo.contextOfAdmin.split('/')[0]}}
                    <span>Payer:</span>{{selectedClient.planAcronym}}
                    <span>Group Name:</span>{{selectedClient.name}}
                    <span>Group Id:</span>{{selectedClient.specifiedId}}
                </div>
                <bmt-manage-auth-at-group (viewAuthorizations)="cancelGroupAuthEdit.emit(true)" (cancelGroupAuthEdit)="selectedClient = null" [userInfo]="userInfoForPopUp"></bmt-manage-auth-at-group>
            </div>
        </rs-dialog>
    </ng-container>
    <!-- Popup of saved auth confirmations -->
    <rs-dialog [visible]="updatingAuthsResp && !updatingAuths" heading="User Authorizations" maxWidth="60%" class="bmt-manager-user-auth" (visiblechange)="updatingAuthsResp = ''">
        <div style="padding: 20px;">
            <p>
                User authorizations are updated
            </p>
        </div>
        <div style="padding: 20px;justify-content: space-between;display: flex;">
            <button style="width: auto;" rs-raised-button color="primary" (click)="cancelGroupAuthEdit.emit(true)">View authorizations</button>
            <button style="width: auto;" rs-raised-button color="primary" (click)="navigateToUserAdmin()">Manage other User</button>
        </div>
    </rs-dialog>
</div>
