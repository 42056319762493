import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddMemberContainer } from './add-member.container';
import { AddMemberComponent } from './add-member.component';
import { AddMemberModule as ADModule, MemberDetailsModule } from '@roosevelt/elig-ui-lib';
import { RouterModule } from '@angular/router';
import { ClientDetailsModule } from '@roosevelt/client-ui-lib';


@NgModule({
    declarations: [AddMemberContainer, AddMemberComponent],
    imports: [
        CommonModule,
        MemberDetailsModule,
        ADModule,
        RouterModule,
        ClientDetailsModule
    ]
})
export class AddMemberModule { }
