import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ITransactions, ITransactionsRes } from '../store/summaryOfChanges/state';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  templateUrl: './summary-of-changes.component.html',
  styleUrls: [ './summary-of-changes.component.scss' ]
})
export class SummaryOfChangesComponent implements OnInit {

  @Output() getTBySession = new EventEmitter<any>();
  @Output() getTBySearch = new EventEmitter<any>();
  @Output() getDetails = new EventEmitter<any>();
  @Output() resetSearchClick = new EventEmitter<any>();
  @Output() getMoreRecords = new EventEmitter<any>();
  sessionRecords: ITransactionsRes;
  searchTRecords: ITransactionsRes;
  // dateMask = { mask: [ /\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/ ], guide: false };
  session = true;
  selectedAdminPlan: any;
  dateForm: UntypedFormGroup;
  isFetching: boolean;
  backToResults: boolean;
  isSearched: boolean;
  showDetails: boolean;
  resetButton: HTMLElement;
  selectedSubClients: any;
  currentPage = 0;
  defaultPageSize = 25;
  selectedIndex = 0;
  transactions: ITransactions[];
  transactionsRecords: ITransactionsRes;

  constructor(private fb: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.searchBySessionId();
    this.setDateForm();
  }

  searchBySessionId() {
    this.getTBySession.emit(true);
  }

  setDateForm() {
    this.dateForm = this.fb.group({
      startDate: [ moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'), Validators.required ],
      endDate: [ moment(new Date()).format('YYYY-MM-DD'), Validators.required ],
    });
  }

  onSearchSubmitted() {
    this.getTBySearch.emit(this.dateForm.getRawValue());
    this.isSearched = true;
    this.resetPagination();
  }

// gives selected group info
subClientSelected(event) {
  this.selectedSubClients = event;
  this.dateForm.reset({
    startDate: moment(new Date()).subtract(1, 'month').format('YYYY-MM-DD'),
    endDate: moment(new Date()).format('YYYY-MM-DD')
  });
  this.backToResults = false;
  this.resetPagination();
}

  clientSearchReset(event) {
    this.selectedSubClients = [];
    this.resetSearchClick.emit(true);
    this.resetPagination();
    this.isSearched = false;
  }

  backToSearchResults() {
    this.backToResults = true;
    this.isSearched = false;
    this.resetSearchClick.emit(true);
    this.resetPagination();
  }

  toggle(session: boolean) {
    this.session = session;
    this.isSearched = false;
    this.resetSearchResults();
    this.resetPagination();
    this.getPagingData();
    this.showDetails = false;
  }

  resetPagination() {
    this.currentPage = 0;
    this.defaultPageSize = 25;
    this.selectedIndex = 0;
  }

  // To clear the client search results.
  resetSearchResults() {
    this.resetButton = document.querySelector('.client-search-buttons .rs-button');
    if (this.resetButton) {
      this.resetButton.click();
    }
  }

  pageChanged(event) {
    if (this.currentPage !== event.pageIndex) {
      this.currentPage = event.pageIndex;
      if (((event.pageIndex+1) * this.defaultPageSize) > this.transactionsRecords.transactions.length &&
        this.transactionsRecords.transactions.length < this.transactionsRecords.totalRecords) {
        this.selectedIndex++;
        this.getMoreRecords.emit(this.selectedIndex * 100);
      } else {
        this.getPagingData();
      }
    }
  }

  getPagingData() {
    this.transactionsRecords = this.session ? this.sessionRecords : this.searchTRecords;
    this.transactions = (this.transactionsRecords.totalRecords !== 0) ? this.transactionsRecords.transactions.slice(((this.currentPage+1) * this.defaultPageSize) - this.defaultPageSize, ((this.currentPage+1) * this.defaultPageSize)) : [];
  }

  selectedTransaction(event) {
    this.showDetails = true;
    this.getDetails.emit(event);
  }

  backToTransactions(event) {
    this.showDetails = false;
  }

}
