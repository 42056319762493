import { Component, OnInit } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { IAdministrators, IRegistrationState } from './store/state';
import { RegistrationAsync } from './store/async';
import { Router } from '@angular/router';
import { AppConfig } from '../shared/app.config';
import { Observable } from 'rxjs';
import { isValidatingInfo } from './store/selector';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  checkForPlan: any;
  planFromUrl: any;

  constructor(private router: Router,
              private lux: Lux<{ registration: IRegistrationState }>,
              private registrationAsync: RegistrationAsync,
              private env: AppConfig) {
  }

  credentials: boolean;
  confirmation: boolean;
  validationToken: string;
  errorMessage: string;
  userInfo: IAdministrators;

  isValidatingInfo$: Observable<boolean> = this.lux.get(isValidatingInfo);
  isValidatingInfo: boolean;

  ngOnInit(): void {
    this.checkForPlan = this.env.plansAllowed;
    // if (this.env.baseURLsAllowed.indexOf(window.location.pathname.split('/')[1]) < 0) {
    //   this.router.navigate(['/404']);
    // }
    this.planFromUrl = document.location.pathname.split('/')[1];
    this.isValidatingInfo$.subscribe(x => this.isValidatingInfo = x);
  }

  registerSubmit(event): void {
    event = { ...event, adminPlan: this.checkForPlan[this.planFromUrl] };
    this.errorMessage = '';
    this.registrationAsync.getValidationToken(event).subscribe(x => {
      if (x && x.validationToken) {
        this.credentials = true;
        this.validationToken = x.validationToken;
      } else {
        this.errorMessage = x;
      }
    });
  }

  credentialsSubmit(event): void {
    this.errorMessage = '';
    this.userInfo = { ...event, validationToken: this.validationToken, adminPlan: this.checkForPlan[this.planFromUrl] };
    this.registrationAsync.getAdministrators(this.userInfo).subscribe(x => {
      if (x.status === 201) {
        this.confirmation = true;
      } else {
        this.errorMessage = x;
        window.scrollTo(0, 0);
      }
    });
  }

}
