<form [formGroup]="achRegistrationForm" class="ach-payment" (ngSubmit)="achPaymentsDetails()">
  <rs-loader *ngIf="isFetching"></rs-loader>
  <h4>Financial Institution Information</h4>
  <div class="row bank-info-content">
    <div class="left-content col-sm-6">
      <div><label>Account Name</label><span>
          <rs-input validation="eager" formControlName="accountName" class="payment-inputs">
          </rs-input>
        </span>
      </div>
      <div><label>Bank Name:</label><span>
          <rs-input validation="eager" formControlName="bankName" class="payment-inputs">
          </rs-input>
        </span>
      </div>
      <div class="account-type">
        <label>
          <h5>Account Type</h5>
        </label>
        <span>
          <rs-radio id="1" name="accountType" label="Checking" value="Checking"
            (change)="accountType = $event.target.value"></rs-radio>
          <rs-radio id="2" name="accountType" label="Savings" value="Savings" (change)="accountType = $event.target.value">
          </rs-radio>
        </span>
      </div>
      <div><label>Account Number:</label><span>
          <rs-input validation="eager" formControlName="accountNumber" class="payment-inputs">
          </rs-input>
        </span>
      </div>
      <div><label>Re-type Account Number:</label><span>
          <rs-input validation="eager" formControlName="confirmAccountNumber" class="payment-inputs"></rs-input>
        </span>
      </div>
      <div><label>Routing Number:</label><span>
          <rs-input validation="eager" formControlName="routingNumber" class="payment-inputs">
          </rs-input>
        </span>
      </div>
      <div><label>Re-type Routing Number:</label><span>
          <rs-input validation="eager" formControlName="confirmRoutingNumber" class="payment-inputs"></rs-input>
        </span>
      </div>
    </div>
    <div class="right-content col-sm-6">
      <h5>Bank Address</h5>
      <div><label>Address Line1:</label><span>
          <rs-input validation="eager" formControlName="line1" class="payment-inputs">
          </rs-input>
        </span>
      </div>
      <div><label>Address Line2::</label><span>
          <rs-input validation="eager" formControlName="line2" class="payment-inputs">
          </rs-input>
        </span>
      </div>
      <div><label>City:</label><span>
          <rs-input validation="eager" formControlName="city" class="payment-inputs"></rs-input>
        </span>
      </div>
      <div><label>State:</label><span>
          <rs-select [items]="dropDownStates" [disabled] = "disableStates" class="payment-inputs" formControlName="state" [searchable]="true"
            validation="eager">
          </rs-select>
        </span>
      </div>
      <div><label>Country:</label><span>
          <rs-select [items]="dropDownCountries" formControlName="country" class="payment-inputs" [searchable]="true"
            validation="eager">
          </rs-select>
        </span>
      </div>
      <div><label>Zip:</label><span>
          <rs-input validation="eager" formControlName="zip" class="payment-inputs"></rs-input>
        </span>
      </div>
    </div>
  </div>
  <h4>Requester Information</h4>
  <div class="row details-row">
    <div class="col-sm-3">
      <rs-input validation="eager" formControlName="firstName" class="payment-inputs" label="First Name">
      </rs-input>
    </div>
    <div class="col-sm-3">
      <rs-input validation="eager" formControlName="lastName" class="payment-inputs" label="Last Name">
      </rs-input>
    </div>

    <div class="col-sm-3">
      <rs-input validation="eager" [mask]="phoneMask" formControlName="phoneNumber" class="payment-inputs" label="Phone Number:">
      </rs-input>
    </div>
    <div class="col-sm-3">

      <rs-input validation="eager" type="email" formControlName="emailAddress" class="create-user-inputs"
        label="Email Address:"></rs-input>
    </div>
  </div>
  <div class="row details-row">
    <div class="col-sm-3">
      <rs-input formControlName="effectiveDate" class="payment-inputs" type="date" placeholder="MM/DD/YYYY"
        label="Effective Date" validation="eager"></rs-input>
    </div>
  </div>
  <div class="row details-row">
    <div class="col submit">
      <button [disabled]="!achRegistrationForm.valid && !accountType" rs-raised-button color="primary">Next</button>
    </div>
  </div>
</form>

<rs-dialog [visible]="showConfirmation && (achPaymentRegistrationDetails| keyvalue)?.length " maxWidth="85%"
  (visibleChange)="dialogClose($event)" heading="Recurring Payment Setup - Authorization">
  <div class="recurring-payment">
  <ng-container *ngIf="isFetching">
    <rs-loader imageSrc={{loaderImage}}></rs-loader>
  </ng-container>
  <div class="error-message" *ngIf="achError">{{achError}}</div>
  <div class="success-message" *ngIf ="isRegistrationSuccess">Your request has been received. Thank you</div>
  <div *ngIf="achPaymentRegistrationDetails && !isRegistrationSuccess" class="confirmation">
  <div class="notify-message" > For your records, please print this page prior to clicking ‘Submit’.</div>
    <div class="confirmation-content">
      <div class="group-details left-content">
        <div>
          <label>Business Unit</label>
          <span>{{achPaymentRegistrationDetails.payer}}</span>
        </div>
        <div>
          <label>Group Name</label>
          <span>{{achPaymentRegistrationDetails.groupName}}</span>
        </div>
        <div>
          <label>Group Number</label>
          <span>{{achPaymentRegistrationDetails.groupSpecifiedIdentifier}}</span>
        </div>
        <div>
          <label>Group Sub-Group Number(s)</label>
          <span>{{achPaymentRegistrationDetails.subgroupSpecifiedIdentifiers}}</span>
        </div>
        <div>
          <label>Name</label>
          <span>{{achPaymentRegistrationDetails.firstName}}</span>
          <span>{{achPaymentRegistrationDetails.lastName}}</span>
        </div>
        <div>
          <label>Phone Number</label>
          <span>{{phoneNumberFormat(achPaymentRegistrationDetails.phoneNumber)}}</span>
        </div>

        <div>
          <label>Email</label>
          <span>{{achPaymentRegistrationDetails.emailAddress}}</span>
        </div>
      </div>
      <div class="banks-details right-content">
        <h4>Financial Institution Information</h4>
        <div>
          <label>Account Name</label>
          <span>{{achPaymentRegistrationDetails.bankAccountDetails.nameOnAccount}}</span>
        </div>
        <div>
          <label>Bank Name</label>
          <span>{{achPaymentRegistrationDetails.bankAccountDetails.bankName}}</span>
        </div>
        <div>
          <label>Bank Address</label>
          <span>{{achPaymentRegistrationDetails.bankAccountDetails.address}}</span>
        </div>
        <div>
          <label>Account Number</label>
          <span>{{achPaymentRegistrationDetails.bankAccountDetails.accountNumber}}</span>
        </div>
        <div>
          <label>Routing Number</label>
          <span>{{achPaymentRegistrationDetails.bankAccountDetails.routingNumber}}</span>
        </div>
        <div>
          <label>Account Type</label>
          <span>{{achPaymentRegistrationDetails.bankAccountDetails.accountType}}</span>
        </div>
      </div>
    </div>
    <div class="acknowledge">
      <ul><li>
      <label>
        <rs-checkbox id="acknowledge" (change)="acknowledgeSelected($event)"></rs-checkbox>
      </label>
      <span> Effective<span>{{achPaymentRegistrationDetails.effectiveDate | date:'MM/dd/yyyy'}}</span><span> I hereby authorize Delta Dental and its subsidiaries, affiliates,
        successors and assigns to initiate recurring automatic withdrawals (ACH) from the account indicated above in
        accordance with my underlying contract with Delta Dental and Delta Dental’s ACH processing policies for the
        payment of premium, admin fees, or claims reimbursement.
        I understand that I am responsible for any fees incurred due to the ACH being rejected or returned for any
        reason by my bank and collection action may be taken. This authorization will remain in full force and effect
        until Delta Dental has received written notification from me of its termination in such time as to afford Delta
        Dental and the Financial Institution a reasonable opportunity to act on it, or until all of my payment
        obligations under the contract have been satisfied. </span> </span></li>
        </ul>
        <ach-payer-address [payer] ="payer"></ach-payer-address>
    </div>
    <div class="footer">
      <span><button (click)="dialogClose(true)" rs-raised-button
          color="secondary">CANCEL</button></span>
      <span><button [disabled]="acknowledge" (click)="register($event)" rs-raised-button color="primary">SUBMIT</button></span>
    </div>
  </div>
  </div>
</rs-dialog>
