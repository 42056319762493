import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { OverageDependentComponent } from './overage-dependent.component';
import { ReportsAsync } from '../../store/reports/async';
import { INotices, IReportsState } from '../../store/reports/state';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from '../../login/store/selectors';
import { ILoginState } from '../../login/store/state';
import { getODNoticeRes, isFetchingODReport, odErrors } from '../../store/reports/selector';
import { UserAuthConfig } from '@roosevelt/elig-ui-lib';
import { initialReportsState } from '../../store/reports/initialState';
import moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'app-overage-dependent',
  template: '<ng-container #overageDependentComponent></ng-container>'
})
export class OverageDependentContainer implements OnInit {
  luxId = 'BMT-OverageDep-Reports' + this.constructor.name + '-OverDNC';

  @ViewChild('overageDependentComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: OverageDependentComponent;

  @Input()
  public get reportType(): 'Notice' | 'Adhoc Report' | 'Term Consolidated Report' {
    return this._reportType;
  }

  public set reportType(value: 'Notice' | 'Adhoc Report' | 'Term Consolidated Report') {
    this._reportType = value;
    if (this.componentInstance) {
      this.componentInstance.reportType = value;
      this.componentInstance.setFormState();
      this.lux.set(state => state.reportsState, { adhocReportError: '', overageDepNoticeError: '', consolidatedReportErrors: '' });
    }
  }

  private _reportType: 'Notice' | 'Adhoc Report' | 'Term Consolidated Report' = 'Notice';

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private reportsAsync: ReportsAsync,
              private lux: Lux<{ reportsState: IReportsState, login: ILoginState }>,
              private authConfig: UserAuthConfig,
              private sanitizer: DomSanitizer) {
  }

  isFetchingODReport$: Observable<boolean> = this.lux.get(isFetchingODReport);
  errors$: Observable<string> = this.lux.get(odErrors);

  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  getODNoticeRes$: Observable<INotices[]> = this.lux.get(getODNoticeRes, this.luxId);

  ngOnInit() {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      OverageDependentComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;

    this.componentInstance.processFormData.subscribe(this.processFormData.bind(this));
    this.componentInstance.resetFormData.subscribe(this.resetFormData.bind(this));
    this.selectedAdminPlan$.subscribe(x => this.componentInstance.selectedAdminPlan = x);
    this.isFetchingODReport$.subscribe(x => this.componentInstance.isFetching = x);
    this.getODNoticeRes$.subscribe(x => {
      this.componentInstance.notices = x;
    });
    this.componentInstance.getODNoticeDoc.subscribe(this.getODNoticeDocument.bind(this));
    this.errors$.subscribe(x => this.componentInstance.errors = x);
    this.componentInstance.reportType = this.reportType;
    this.lux.set(state => state.reportsState, { adhocReportError: '', overageDepNoticeError: '', consolidatedReportErrors: '' });
  }

  processFormData(value) {
    this.lux.set(state => state.reportsState, { adhocReportError: '', overageDepNoticeError: '', consolidatedReportErrors: '' });
    if (this.reportType === 'Notice') {
      this.lux.set(state => state.reportsState.overageDepNoticeReq.searchCriteria, value);
      this.reportsAsync.getODNotice().subscribe();
    } else if (this.reportType === 'Adhoc Report') {
      const req = {
        ...value,
        startOfAgeRange: undefined,
        uptoAgeRange: undefined,
        asOf: moment(value.asOf).format('YYYY-MM-DD'),
        ageRange: {
          start: value.startOfAgeRange,
          end: value.uptoAgeRange
        }
      };
      // might be able to remove this... not sure why state has to hold on to this..
      this.lux.set(state => state.reportsState.adhocReportReq, req);
      this.reportsAsync.getAdhocReport(req).subscribe(res => this.componentInstance.adhocReportUrl = this.formExcelBlobUrl(res, 'adhocReportError'));
    } else if (this.reportType === 'Term Consolidated Report') {
      this.reportsAsync.getConsolidatedReport(value).subscribe(res => this.componentInstance.consolidatedReportUrl = this.formExcelBlobUrl(res, 'consolidatedReportError'));
    }
  }

  formExcelBlobUrl(blobResponse, errorKey: 'adhocReportError' | 'consolidatedReportError') {
    if (blobResponse !== null) {
      const blob = new Blob([blobResponse], { type: 'application/vnd.ms-excel' });
      if (blob.size !== 0) {
        const downloadURL = URL.createObjectURL(blob);
        return this.sanitizer.bypassSecurityTrustResourceUrl(downloadURL);
      }
    } else {
      this.lux.set(state => state.reportsState, { [errorKey]: 'No data based on query' });
      return undefined;
    }
  }

  resetFormData() {
    this.componentInstance.notices = [];
    this.lux.set(state => state.reportsState.overageDepNoticeRes, initialReportsState.overageDepNoticeRes);
  }

  // for testing
  // getODNoticeDocument(req) {
  //   this.reportsService.getODNoticeDoc(req).subscribe(res => {
  //     const file = new Blob([res], { type: 'application/pdf' });
  //     const fileURL = URL.createObjectURL(file);
  //     window.open(fileURL);
  //     const blob = new Blob([res], { type: 'application/octet-stream' });
  //     const file = this.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  //     this.componentInstance.sanContent = file;
  //     }
  //   );
  // }

  getODNoticeDocument(req) {
    this.reportsAsync.getPDFDocument(req).subscribe();
  }

  setPlanDropDowns(adminPlan) {
    this.componentInstance.payerOptions = this.authConfig.userAuthorizations[adminPlan] as unknown as string[];
  }

}
