import { Component, Inject, Input } from '@angular/core';

@Component({
  template: ''
})
export class FeatureComponent<T> {

  @Input()
  get features(): T[] {
    return this._features;
  }
  set features(value: T[]) {
    this._features = value;
  }

  private _features: T[];

  constructor(@Inject('allFeatures') public allFeatures: T[]) { }
}
