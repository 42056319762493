import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class SummaryOfChangesApiService {
  constructor(private http: HttpClient, private config: AppConfig, private internalAuth: BasicAuthConfig) {
  }

  getTransactions(req) {
    return this.http.post<any>(this.config.userTransactionUrl, req);
  }

  getMemberEligHistory(req) {
    return this.http.post<any>(this.config.memberEigHistoryUrl, req);
  }

  getCobHistory(req) {
    const url = this.config.historyBaseUrl + 'cobs/history';
    return this.http.post<any>(url, req);
  }

  getCoverageHistory(req) {
    return this.http.post<any>(this.config.memberHistorySearchUrl, req);
  }

  getPersonHistory(memberId: number, req) {
    const url = this.config.personHistoryBaseUrl + memberId + '/search';
    if (this.internalAuth.authedUrls.indexOf(url) === -1) {
      this.internalAuth.authedUrls = [...this.internalAuth.authedUrls, url];
    }
    return this.http.post<any>(url, req);
  }

  getAddressHistory(memberId: number, req) {
    const url = this.config.personHistoryBaseUrl + memberId + '/addresses/search';
    if (this.internalAuth.authedUrls.indexOf(url) === -1) {
      this.internalAuth.authedUrls = [...this.internalAuth.authedUrls, url];
    }
    return this.http.post<any>(url, req);
  }
}
