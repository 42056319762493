import { Injectable } from '@angular/core';
import { catchError, first, mergeMap, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { IAddressHistory, ICOBHistory, ICoverageHistory, IEligHistory, IPersonHistory, ISummaryOfChangesState } from './state';
import { SummaryOfChangesApiService } from './api.service';
import { getCobSearchReq, getCoverageSearchReq, getMemberEligSearchReq, getPersonAndAddressSearchReq, getTSearchReq, getTSessionReq } from './selector';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class SummaryOfChangesAsync {
  constructor(private lux: Lux<{ sChanges: ISummaryOfChangesState }>,
              private scService: SummaryOfChangesApiService,
  ) {
  }

  getTBySessionId(moreRecords?) {
    return this.lux.get(getTSessionReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingTransactions: true })),
      mergeMap(x => this.scService.getTransactions(x)),
      tap(results => {
        this.lux.set(x => x.sChanges, !moreRecords ? { sessionTransactionsRes: results, isFetchingTransactions: false } :
          x => ({
            isFetchingTransactions: false,
            sessionTransactionsRes: {
              ...x.sessionTransactionsRes,
              transactions: [ ...x.sessionTransactionsRes.transactions, ...results.transactions ]
            }
          }));
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          err.error.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.sChanges, { summaryOfChangeError: errorMessage, isFetchingTransactions: false });
        return of(errorMessage);
      })
    );
  }

  getTBySearch(moreRecords?) {
    return this.lux.get(getTSearchReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingTransactions: true })),
      mergeMap(x => this.scService.getTransactions(x)),
      tap(results => {
        this.lux.set(x => x.sChanges, !moreRecords ? { searchTransactionsRes: results, isFetchingTransactions: false } :
          x => ({
            isFetchingTransactions: false,
            searchTransactionsRes: {
              ...x.searchTransactionsRes,
              transactions: [ ...x.searchTransactionsRes.transactions, ...results.transactions ]
            }
          }));
      }),
      catchError((err: HttpErrorResponse) => {
        const errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          err.error.apiErrorList.map(item => item.errorMessage).join('\n');
        }
        this.lux.set(x => x.sChanges, { summaryOfChangeError: errorMessage, isFetchingTransactions: false });
        return of(errorMessage);
      })
    );
  }

  // getting details
  getMemberEligHistory() {
    return this.lux.get(getMemberEligSearchReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingDetails: true })),
      mergeMap(x => this.scService.getMemberEligHistory(x)),
      tap(results => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false, memberEligHistoryRes: results.eligibilityTransactions as IEligHistory[] });
      }),
      catchError(err => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false });
        return of(null);
      })
    );
  }

  getCobHistory() {
    return this.lux.get(getCobSearchReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingDetails: true })),
      mergeMap(x => this.scService.getCobHistory(x)),
      tap(results => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false, cobHistoryRes: results.coordinationOfBenefitTransactions as ICOBHistory[] });
      }),
      catchError(err => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false });
        return of(null);
      })
    );
  }

  getCoverageHistory() {
    return this.lux.get(getCoverageSearchReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingDetails: true })),
      mergeMap(x => this.scService.getCoverageHistory(x)),
      tap(results => {
        this.lux.set(x => x.sChanges, {
          isFetchingDetails: false, coverageHistoryRes: results.coverageTypeTransactions as ICoverageHistory[]
        });
      }),
      catchError(err => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false });
        return of(null);
      })
    );
  }


  getPersonHistory(member) {
    return this.lux.get(getPersonAndAddressSearchReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingDetails: true })),
      mergeMap(x => this.scService.getPersonHistory(member, x)),
      tap(results => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false, personHistoryRes: results.personHistory as IPersonHistory[] });
      }),
      catchError(err => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false });
        return of(null);
      })
    );
  }

  getAddressHistory(member) {
    return this.lux.get(getPersonAndAddressSearchReq).pipe(
      first(),
      tap(() => this.lux.set(x => x.sChanges, { isFetchingDetails: true })),
      mergeMap(x => this.scService.getAddressHistory(member, x)),
      tap(results => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false, addressHistoryRes: results.addressHistory as IAddressHistory[] });
      }),
      catchError(err => {
        this.lux.set(x => x.sChanges, { isFetchingDetails: false });
        return of(null);
      })
    );
  }
}
