import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AppConfig } from '../shared/app.config';
import { DOCUMENT } from '@angular/common';

const magnoliaUrls = {
  headerFooter: '/header_footer',
  help: '/help/main/page',
  login: '/login/main/page'
};

@Injectable({
  providedIn: 'root'
})
export class MagnoliaService {

  constructor(@Inject(DOCUMENT) private doc: Document, private http: HttpClient, private config: AppConfig) {
  }

  getMagnoliaContent(page) {
    const baseUrl = this.doc.location.pathname.split('/')[1];
    return this.http.get(this.config.magnoliaUrl + ((baseUrl === 'uat40') ?
      this.doc.location.pathname.split('/')[2] : baseUrl || 'delta').toUpperCase() + magnoliaUrls[page], { responseType: 'text' });
  }

}
