import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { IAddressHistory, ICOBHistory, ICoverageHistory, IEligHistory, IPersonHistory, ITransactions } from '../../store/summaryOfChanges/state';

@Component({
  templateUrl: './summary-details.component.html',
  styleUrls: ['./summary-details.component.scss']
})
export class SummaryDetailsComponent implements OnInit {

  @Output() backSearch = new EventEmitter<any>();
  memberEligHistory: IEligHistory[];
  cobHistory: ICOBHistory[];
  coverageHistory: ICoverageHistory[];
  personHistory: IPersonHistory[];
  addressHistory: IAddressHistory[];
  transaction: ITransactions;

  constructor() {
  }

  ngOnInit() {
  }

}
