import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { timeout } from 'rxjs';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib/core';

@Injectable()
export class BMTAuthInterceptor implements HttpInterceptor {

  constructor(private config: BasicAuthConfig) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const timeoutValue = Number(request.headers.get('timeout')) || this.config.defaultTimeout;
    const sessionId = sessionStorage.getItem('sessionId') || '';
    const oktaSession = sessionStorage.getItem('okta-token-storage');
    let acc_token = '';
    if (oktaSession?.indexOf('accessToken') > -1) {
      acc_token = JSON.parse(oktaSession).accessToken.accessToken;
    }
    const idToken = sessionStorage.getItem('impersonate_id_token') || sessionStorage.getItem('impersonate_access_token') || acc_token || sessionStorage.getItem('id_token') as string;
    const impersonationUser = sessionStorage.getItem('impersonate_user');
    if (idToken) {
      request = request.clone({
        ...request, setHeaders: {
          'Authorization': 'Bearer ' + idToken,
          ...(impersonationUser ? { impersonation_user: impersonationUser } : {}),
          'Content-Type': 'application/json',
          'session_id': sessionId
        }
      });
    }
    return next.handle(request).pipe(timeout(+timeoutValue));
  }
}
