import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from './profile.component';
import { AuthorizationsComponent } from './authorizations/authorizations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageUserOrAuthModule } from '../manage-user-or-auth/manage-user-or-auth.module';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { RouterModule } from '@angular/router';
import {ChangePasswordModule, MultiFactorModule} from '@roosevelt/authentication-lib';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';

@NgModule({
  declarations: [
    ProfileComponent,
    AuthorizationsComponent,
    UpdatePasswordComponent
  ],
  imports: [
    CommonModule,
    LoaderModule,
    PaginatorModule,
    FormsModule,
    DialogModule,
    ManageUserOrAuthModule,
    RouterModule,
    ReactiveFormsModule,
    InputModule,
    ButtonModule,
    MultiFactorModule,
    ChangePasswordModule
  ]
})
export class ProfileModule {
}
