export const initialAlertsState: IAlertsState = {
  alerts: {
    generalAlerts: [],
    pagination: {
      totalRecords: 0,
      offset: 0,
      limit: 0
    }
  },
  isFetchingAlerts: false,
  errors: ''
};

export interface IAlertsState {
  alerts: IBmtAlerts;
  isFetchingAlerts: boolean;
  errors: string;
}

export interface IBmtAlerts {
  generalAlerts: IGeneralAlerts[];
  pagination: {
    totalRecords: number,
    offset: number,
    limit: number
  };
}

export interface IGeneralAlerts {
  updatedBy: string;
  statusType: ICodeValue;
  subject: string;
  updatedOn: string;
  message: string;
  applicationTypeKey: string;
  priorityType: ICodeValue;
  createdOn: string;
  recipientType: ICodeValue;
  adminPlanAcronym: string;
  createdBy: string;
  alertId: number;
  isAlertForBenefits: boolean;
  startDate: string;
}

export interface ICodeValue {
  code: string;
  value: string;
}

