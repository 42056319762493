import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ManageEligibilityComponent } from './manage-eligibility.component';
import { Observable, Subscription } from 'rxjs';
import { IClientPlanDetails, IMassActionState } from '../store/massActions/state';
import { getContractResponse, getSourceSelectedSubClient, isFecthingContractInfo, isShowDestinationClientDetails } from '../store/massActions/selector';
import { IMemberState } from '../store/member/state';
import { MassActionAsync } from '../store/massActions/async';
import moment from 'moment';
import { ILoginState } from '../login/store/state';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ManageEligibility } from 'src/app/features/manage-eligibility';
import { FeatureComponent } from 'src/app/utils/feature-component';
import { IAppAuthorizations, IUiAuthorizations } from 'src/app/authorizations/state';
import { EligUiAuthSerive } from '@roosevelt/elig-ui-lib';
import { getRelaxedUiAuth, isFetchingUiAuth } from 'src/app/authorizations/selector';
import { Router } from '@angular/router';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'app-manage-eligibility',
  template: '<ng-container #manageEligibilityComponent></ng-container>'
})
export class ManageEligibilityContainer extends FeatureComponent<typeof ManageEligibility> implements OnInit, OnDestroy {
  luxId = 'BMT-ManageElig-Comp' + this.constructor.name;

  @ViewChild('manageEligibilityComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: ManageEligibilityComponent;
  massActionStoreSub: Subscription;
  memberStoreSub: Subscription;
  // userAuths$: Observable<IUserAuthorizations> = this.lux.get(getUserAuths, this.luxId);
  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  relaxedAuth$: Observable<IUiAuthorizations | {}> = this.lux.get(getRelaxedUiAuth, this.luxId);
  contractResponse$: Observable<IClientPlanDetails> = this.lux.get(getContractResponse, this.luxId);
  isFetchingUiAuth$: Observable<boolean> = this.lux.get(isFetchingUiAuth, this.luxId);
  eligReasons: { subscriber: { active: any; }; };

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private lux: Lux<{ massAction: IMassActionState, member: IMemberState, login: ILoginState, appUiAuth: IAppAuthorizations }>,
    private msAsync: MassActionAsync,
    private eligUiAuth: EligUiAuthSerive,
    private route: Router
  ) {
    super([]);
  }

  showDestinationClient$: Observable<any> = this.lux.get(isShowDestinationClientDetails, this.luxId);
  sourceSelectedSubClient$: Observable<any> = this.lux.get(getSourceSelectedSubClient, this.luxId);
  isFecthingContractInfo$: Observable<boolean> = this.lux.get(isFecthingContractInfo, this.luxId);

  featureHeadinMapping = {
    'member': 'Member',
    'addMember': 'Add Member'
  };

  ngOnInit() {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ManageEligibilityComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
    this.relaxedAuth$.subscribe((uiAuth: IUiAuthorizations) => {
      this.eligUiAuth.setRelaxedAuthorizations(uiAuth.eligibility);
      this.componentInstance.manageEligFeatures = (uiAuth as IUiAuthorizations).manageeligibility;
      this.componentInstance.subHeader = this.componentInstance.manageEligFeatures && this.componentInstance.manageEligFeatures.includes('member') ?
        this.featureHeadinMapping.member : this.featureHeadinMapping[this.componentInstance.manageEligFeatures[0]];
    });
    this.showDestinationClient$.subscribe(x => this.componentInstance.showDestinationClient = x);
    this.sourceSelectedSubClient$.subscribe(x => this.componentInstance.sourceSelectedSubClient = x);

    this.selectedAdminPlan$.subscribe(adminPlan => this.componentInstance.selectedAdminPlan = adminPlan);
    this.contractResponse$.subscribe(contractData => this.setUpFamilyEnrollDefaultDropdowns(contractData));
    this.isFetchingUiAuth$.subscribe(fetchingAuth => this.componentInstance.isFetchingUiAuth = fetchingAuth);
    this.isFecthingContractInfo$.subscribe(fetchingContract => this.componentInstance.isFecthingContractInfo = fetchingContract);

    // Setting up the state
    this.massActionStoreSub = this.componentInstance.setMassActionStore.subscribe(storeData => {
      this.lux.set(state => state.massAction, { ...storeData });
      // Trigger the call to fetch contract info (needed only for family enrollment)
      if (this.componentInstance.subHeader === 'Family Enrollment') {
        if (this.componentInstance.selectedSubClients && !!this.componentInstance.selectedSubClients.length) {
          const contractReqObj = {
            planAcronym: this.componentInstance.selectedSubClients[0].planAcronym,
            client: {
              specifiedId: this.componentInstance.selectedSubClients[0].client.specifiedId,
            },
            subClient: {
              specifiedId: this.componentInstance.selectedSubClients[0].specifiedId,
            },
            asOf: moment(this.componentInstance.selectedSubClients[0].asOfDate).format('YYYY-MM-DD'),
            view: [
              'client_basic',
              'client_idCardConfig',
              'client_routeConfig',
              'subclient_basic',
              'subclient_idCardConfig',
              'subclient_memberConfig',
              'contract_basic',
              'contract_benefitsConfig',
              'contract_cobConfig',
              'contract_eligAgeLimitsConfig',
              'contract_eligConfig',
              'contract_restrictionConfig',
              'subclient_routeConfig',
              'contract_eligStatusReasonConfig',
              'client_administrative'
            ]
          };
          this.lux.set(state => state.massAction.contractInfo.requestObj, contractReqObj);
          this.msAsync.getContractInfo().subscribe();
        }
      }
    });
    this.memberStoreSub = this.componentInstance.setMemberStore.subscribe(storeData => {
      const event = storeData.event;
      delete storeData.event;
      this.lux.set(state => state.member, {
        ...storeData
      });
      if (event === 'add flow') {
        this.route.navigate([this.componentInstance.subHeader === 'Family Enrollment' ? '/family-enrollment' : '/add-member']);
      } else if (typeof event !== 'string') {
        this.route.navigate(['details']);
      }

    });
  }

  setUpFamilyEnrollDefaultDropdowns(contractResposnse) {
    if (contractResposnse) {
      this.componentInstance.displayFamilyEnrollDefaultsForm = true;
      this.componentInstance.contractResposnse = contractResposnse as IClientPlanDetails;
      const contract = contractResposnse && contractResposnse.client && contractResposnse.client.subClient && contractResposnse.client.subClient.contract ? contractResposnse.client.subClient.contract : null;
      const eligReasonConfig = contract ? contract.eligibilityStatusReasonConfig : null;
      if (eligReasonConfig) {
        this.eligReasons = {
          subscriber: {
            active: this.createDropDownOptions(eligReasonConfig.activeSubscriberEligStatusReasonTypes)
          }
        };
        this.componentInstance.eligStatusReasons = this.eligReasons['subscriber'].active;
      }
      const qualifierConfig = contract && contract.eligibilityConfig && contract.eligibilityConfig.specialAttributeTypes ? this.createDropDownOptions(contract.eligibilityConfig.specialAttributeTypes) : null;
      this.componentInstance.qualifierTypes = qualifierConfig && !!qualifierConfig.length ? qualifierConfig.filter(qualifier => qualifier.label === 'Retiree' || qualifier.label === 'COBRA') : [];
      const memberBenefitConfig = contract && contract.benefitsConfig && contract.benefitsConfig.memberBenefitTypes ? this.createDropDownOptions(contract.benefitsConfig.memberBenefitTypes) : null;
      this.componentInstance.memberBenefitTypes = memberBenefitConfig && !!memberBenefitConfig.length ? memberBenefitConfig : [];
      this.componentInstance.setFamilyEnrollDefaultsForm();
    } else {
      this.componentInstance.displayFamilyEnrollDefaultsForm = false;
    }
  }

  createDropDownOptions(reasons: any) {
    return reasons.map(x => {
      return x = {
        value: x.code,
        label: x.value
      };
    });
  }

  ngOnDestroy() {
    this.massActionStoreSub.unsubscribe();
    this.memberStoreSub.unsubscribe();
    this.lux.destroy(this.luxId);
  }

}
