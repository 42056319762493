import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import { errorsInUpdatingAuths, getAdminClients, getManageUserAuthsResp, updatingAuthInProgress } from 'src/app/store/createManageUser/selector';
import { IAdminClients, ICreateManageUserState, IManageuser, initialCreateManageUserState } from 'src/app/store/createManageUser/state';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'bmt-manage-auth',
  templateUrl: './manage-auth.component.html',
  styleUrls: ['./manage-auth.component.scss']
})
export class ManageAuthComponent implements OnInit, OnDestroy {
  luxId = 'BMT-manage-auth' + this.constructor.name;

  errorsInUpdatingAuths$: Observable<string> = this.lux.get(errorsInUpdatingAuths, this.luxId);
  updatingAuthInProgress$: Observable<boolean> = this.lux.get(updatingAuthInProgress, this.luxId);
  updatingAuthsDone$: Observable<string> = this.lux.get(getManageUserAuthsResp, this.luxId);
  getAdminClients$: Observable<IAdminClients[]> = this.lux.get(getAdminClients, this.luxId);

  @Input() userInfo: IManageuser;
  @Output() cancelEdit: EventEmitter<boolean> = new EventEmitter();
  canManageAtClientLevel = false;
  selectedClient = '';
  initailAdminUserAuthResponse = initialCreateManageUserState.adminAndUserAuths;

  constructor(private router: Router, private lux: Lux<{ createManageUserState: ICreateManageUserState }>, private createManageUserAsync: CreateManageUserAsync) {
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  clearTheAdminUserState() {
    this.lux.set(state => state.createManageUserState.manageUserAuths, { authResponse: '', errors: '' });
    this.lux.set(state => state.createManageUserState.adminAndUserAuths, {
      authResponse: this.initailAdminUserAuthResponse.authResponse,
      errors: ''
    });
  }

  ngOnInit(): void {
    this.clearTheAdminUserState();
    const adminRole = this.userInfo.adminRole;
    const singleClientOrSubGroupAdmins = ['BMT-GROUP-ADMIN', 'BMT-SUBGROUP-ADMIN', 'BMT-GROUP-DELEGATE', 'BMT-SUBGROUP-DELEGATE'];
    // (adminRole === 'BMT-CLIENT-ASSOCIATION-ADMIN' || adminRole === 'BMT-AGENT-ADMIN' || adminRole === 'BMT-AGENCY-ADMIN' || adminRole === 'BMT-CLIENT-ASSOCIATION-DELEGATE' || adminRole === 'BMT-AGENT-DELEGATE' || adminRole === 'BMT-AGENCY-DELEGATE');
    this.canManageAtClientLevel = !singleClientOrSubGroupAdmins.includes(adminRole);
  }

  navigateToUserAdmin() {
    this.router.navigate(['userAdmin/manage']);
  }

}
