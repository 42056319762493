import { EligEnvConfig } from '@roosevelt/elig-ui-lib';
import { ClientEnvConfig } from '@roosevelt/client-ui-lib';
import { BenefitsEnvConfig } from '@roosevelt/benefits-ui-lib';
import { SatConfig } from '@roosevelt/sat-lib';
import { MemberBenefitsConfig } from '@roosevelt/member-benefits-lib';
import { ProcedureConfig } from '@roosevelt/procedures-lib';
import { DentistDirEnvConfig } from '@roosevelt/dentist-dir-ui-lib';
import { IBillingAuthConfig } from '@roosevelt/billing-lib';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib/core';

export class AppConfig implements EligEnvConfig, ClientEnvConfig, BasicAuthConfig, BenefitsEnvConfig, SatConfig, MemberBenefitsConfig, ProcedureConfig, DentistDirEnvConfig, IBillingAuthConfig {

  issuer: string;
  redirectUri: string;
  scopes: string[];
  secureAuth: { use: boolean; clientId: any; authEndpoint: any; authInfoUrl: any; authNewClientEndpoint: any; authLogoutEndPoint: any; loginRedirectRoute: any; };
  region: string;
  authInfoUrl: string;
  sessionIdUrl: string;
  appName: string;
  defaultTimeout: number;
  authedUrls: string[];
  clientId: string;
  authEndpoint: string;
  nonce: string;
  loginRedirectRoute: string;
  authLogoutEndPoint: string;
  passwordResetUrl: string;
  changePasswordUrl: string;
  renPasswordResetUrl: string;
  renChangePasswordUrl: string;
  authNewClientEndpoint: string;
  overrides: {};

  /*---------silent refresh---------*/
  postLoginRedirectEndpoint: string;
  logoutEndpoint: string;
  postLogoutRedirectEndpoint: string;
  userInfoSessionStorageKey: string;
  userInfoTokenValidDurationPath: string;

  /*---------Member Admin URL's---------*/
  applicationName: string;
  memberPlanSearchUrl: string;
  massReinstateUrl: string;
  massTerminateUrl: string;
  massTransferUrl: string;
  massActionsReportUrl: string;
  userTransactionUrl: string;
  overageDependentNoticeUrl: string;
  overageDependentNoticeDocUrl: string;
  adhocReportUrl: string;
  consolidatedReportUrl: string;
  customGroupReportUrl: string;
  /*---------Eligibility URL's---------*/
  membersSearchUrl: string;
  memberSearchUrl: string;
  advanceSearchUrl: string;
  memberProductSearchUrl: string;
  memberDetailsByIdUrl: string;
  memberDependentByIdUrl: string;
  memberEigHistoryUrl: string;
  memberHistorySearchUrl: string;
  updateMemberUrl: string;
  updateMemberDependentUrl: string;
  generateAltIdUrl: string;
  reinstateSubscriberUrl: string;
  reinstateDependentUrl: string;
  terminateSubscriberUrl: string;
  terminateDependentUrl: string;
  additionalAttributesUrl: string;
  aggregateHistoryUrl: string;
  addSubscriberUrl: string;
  addDependentUrl: string;
  getCountries: string;
  transferMemberUrl: string;
  copyMemberUrl: string;
  historyBaseUrl: string;
  clientFieldsHistoryUrl: string;
  personHistoryBaseUrl: string;
  orderIdCardUrl: string;
  customerServicePhone: string;
  paperClaimsAddress: string;
  appealInquiresAddress: string;
  calculateEligEffDateUrl: string;
  findMemberUrl: string;
  uiAuthorizationsUrl: string;
  addFamilyUrl: string;
  deleteDependentPersonIdentifierUrl: string;
  personsByMergedPersonIdUrl: string;
  custodialParentHistoryUrl: string;
  custodialParentAuditsUrl: string;
  personIdentifierHistoryUrl: string;
  /*---------Client Lib URL's---------*/
  clientSearchUrl: string;
  subClientSearchUrl: string;
  additionalInfoClientSearchUrl: string;
  clientPlanDetailsUrl: string;
  clientProductDetailsUrl: string;
  clientReservationsUrl: string;
  contractSearchUrl: string;
  pendingGroupSearchUrl: string;
  prefundsUrl: string;
  getBillingRatesUrl: string;
  getContractDataUrl: string;
  getGroupsBillingConfig: string;
  getAgentAgenciesUrl: string;
  getSubgroupAgentAgenciesUrl: string;
  getLastBilledSubscriberCountUrl: string;
  groupAssociationsUrl: string;
  /*---------Benefits Lib URL's---------*/
  benefitsUrl: string;
  accumulatorUrl: string;
  statesUrl: string;
  groupFeeUrl: string;
  rulePackageUrl: string;
  ruleComponentInfoUrl: string;
  extendedRulesUrl: string;
  orthoFeeUrl: string;
  visionBenefitsUrl: string;
  /*----------- Authorizations URL's ----------*/
  userProfileUrl: string;
  userResourcesUrl: string;
  contactCenterInfoUrl: string;
  // Magnolia URL
  magnoliaUrl: string;

  // Security Question
  validationUrl: string;
  administratorsUrl: string;
  securityQuestions: string[];

  // Sat URL's
  stateListUrl: string;
  countriesListUrl: string;
  providerSearchUrl: string;
  codeValuesUrl: string;
  familyDetailsUrl: string;
  submitClaimUrl: string;
  toothSurfacesUrl: string;
  claimProcessValidationsUrl: string;
  claimSummaryUrl: string;
  claimDetailUrl: string;
  claimPaymentDetailsUrl: string;
  claimAdminDetailsUrl: string;
  claimDocumentsSearchUrl: string;
  renProviderSearchUrl: string;
  appType: string;
  claimNotepadSearchUrl: string;
  loaderType: 'DEFAULT';

  // Procedure Code URL's
  getProcedureCodesUri: string;

  // Member Benefits URL's
  memberBenefitsUrl: string;
  routineProceduresUrl: string;
  cobConfigUrl: string;
  carryoverIncentiveMaxUrl: string;
  waitingPeriodsUrl: string;
  baseUrl: string;
  /*----------- Dentist Dir URL's ----------*/
  dentistNetworksUrl: string;
  stateInfoUrl: string;
  dentistDirectoryDocumentUrl: string;

  /*----------- Billing URL's ----------*/
  totalBalanceDueUrl: string;
  consolidationsSearchUrl: string;
  consolidationsInvoiceUrl: string;
  nonConsolidationsSearchUrl: string;
  nonConsolidationsInvoiceUrl: string;
  consolidationsReportsInvoiceUrl: string;
  nonConsolidationsReportsInvoiceUrl: string;
  availableReportsUrl: string;
  downloadDocumentUrl: string;
  consolidationReportsFormatUrl: string;
  nonConsolidationReportsFormatUrl: string;
  eBillUrl: string;
  peopleSoftUrl: string;
  paymentConfigurationsUrl: string;
  achPaymentRegistrationUrl: string;
  achPayRegistrationUrl: string;
  paymentHistoryUrl: string;
  paymentsActivityUrl: string;
  customerPaymentProfilesUrl: string;
  merchantAccountUrl: string;
  addCustomerProfileUrl: string;
  addPaymentProfileUrl: string;
  paymentUrl: string;
  consolidatedInvoiceActivityUrl: string;
  nonConsolidatedInvoiceActivityUrl: string;
  documentAvailableReportsUrl: string;
  downloadsSelectedReportsUrl: string;
  /*----------- CK URL's ----------*/
  clientKnowledgeUrl: string;
  /*----------- BMT Alrets -----------*/
  bmtAlertsUrl: string;
  getPaymentDueNotification: string;
  /*----------- Create/Manage User ----------*/
  createUserUrl: string;
  listOfUserForAdminUrl: string;
  userContextUrl: string;
  userWholeContextUrl: string;
  adminAndUserAuthorizationsUrl: string;
  manageUserAuthorizationsUrl: string;
  enableUser: string;
  disableUser: string;
  updateUserInfo: string;
  userInfoUrl: string;
  updateUserInfoScimUrl: string;

  /*---------- Plans Allowed for BMT-----------*/
  plansAllowed: {};
  googleAnalyticsKeys: {};
  googleTagManager: {};
  trustGuardSecuritySeals: {};

  /*---------- mfa-----------*/
  urls: {
    factorsEnrolled: string;
    factorsCatalog: string;
    sendCode: string;
    verifyCode: string;
    resendCode: string;
  };
  enableMfa: boolean;
  getExcelAttributeMappingUrl: string;
  getExcelFinalDataUrl: string;
  getGroupInformationUrl: string;
  getValidPayersListUrl: string;
}
