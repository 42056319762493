<rs-loader *ngIf="isFetchingContext || userLoginInProgress || isFetchingUserLists"></rs-loader>
<div [innerHTML]="themeStyleLinks"></div>
<div *ngIf="region && region !== 'prod'" class="env-banner noprint"
  [class.env-banner-tst]="region.toLowerCase() === 'tst'"
  [class.env-banner-uat]="region.toLowerCase() === 'uat' || region.toLowerCase() === 'uat40'"
  [class.env-banner-local]="region.toLowerCase() === 'local'"
  [class.env-banner-preprd]="region.toLowerCase() === 'preprd'">
  <ul class="list-inline list-unstyled env-banner-list">
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
    <li>{{region}}</li>
  </ul>
</div>
<div *ngIf="isImpersonating" class="impersonation"
  [class.cursor]="(region === 'tst' || region === 'local')">
  Impersonating
  <span style="color: red;">&nbsp;{{impersonationUser}}</span>
  <span style="flex: 1;"></span>
  click <a (click)="closeThisTab()">&nbsp;here&nbsp;</a> to stop impersonation and close window
</div>
<!-- Magnolia Header content -->
<ng-container *ngIf="magnoliaHeaderContent">
  <div class="noprint" (click)="headerClick($event)" [innerHTML]="magnoliaHeaderContent"
    *ngIf="!urlNotDefined && activeUrl !== '/login' && activeUrl !== '/' && !registrationPage && activeUrl !== '/404'"
    [class.banner-spacing]="region && region !== 'prod'">
  </div>
</ng-container>
<div class="container-fluid">
  <div class="row" style="background-color: white; margin: 0;">
    <app-left-nav
      *ngIf="!urlNotDefined && activeUrl !== '/login' && activeUrl !== '/' && !registrationPage && activeUrl !== '/404'">
    </app-left-nav>
    <div class="col" style="padding: 0;width: calc(100% - 125px);min-height: calc(100vh - 192px);">
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- Alerts container -->
  <div class="bmt-alerts noprint" (click)="viewAlerts()"
    *ngIf="!urlNotDefined && activeUrl !== '/login' && activeUrl !== '/' && !registrationPage">
    <span class="alert-count">{{bmtAlerts.length}}</span>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 456.727 456.727" style="enable-background:new 0 0 456.727 456.727;" xml:space="preserve">
      <g>
        <path d="M415.863,315.864l-30-30v-80.366c0-76.725-54.865-140.625-127.5-154.634v-20c0-16.542-13.458-30-30-30s-30,13.458-30,30v20
        c-72.635,14.01-127.5,77.909-127.5,154.634l0,80.366l-30,30v40h-30v30h30h375h30v-30h-30V315.864z" />
        <path
          d="M228.363,455.863c27.922,0,52.019-16.353,63.255-40H165.109C176.344,439.51,200.441,455.863,228.363,455.863z" />
        <path d="M427.262,167.464l29.465-5.638c-6.407-33.488-20.473-65.803-40.674-93.451c-20.075-27.474-46.39-50.603-76.099-66.884
        L325.536,27.8C378.784,56.981,415.861,107.887,427.262,167.464z" />
        <path d="M131.19,27.8L116.772,1.491c-29.709,16.282-56.023,39.41-76.099,66.884C20.473,96.024,6.407,128.338,0,161.827
        l29.465,5.638C40.865,107.887,77.942,56.981,131.19,27.8z" />
      </g>
    </svg>
  </div>
</div>
<!-- Magnolia Footer content -->
<div class="footer-content noprint" *ngIf="!urlNotDefined && activeUrl !== '/404' && activeUrl !== '/'"
  [innerHTML]="magnoliaFooterContent" style="height: 192px;"></div>
<a *ngIf="activeUrl === '/login'" name="trustlink" href="{{'https://secure.trust-guard.com/security/' + trustGuardUrl}}" rel="nofollow" target="_blank" onclick="var nonwin=navigator.appName!='Microsoft Internet Explorer'?'yes':'no'; window.open(this.href,'welcome','location='+nonwin+',scrollbars=yes,width=517,height='+screen.availHeight+',menubar=no,toolbar=no'); return false;"
   oncontextmenu="var d = new Date(); alert('Copying Prohibited by Law - This image and all included logos are copyrighted by trust-guard \251 '+d.getFullYear()+'.'); return false;"><img name="trustseal" alt="Security Seals" style="border: 0; margin-top: -80px" src="{{'//secure.trust-guard.com/seals/security/'+trustGuardUrl+'-small.gif'}}" /></a>
<!-- Alerts Pop up -->
<ng-container *ngIf="showBmtAlerts">
  <rs-dialog visible="true" (visibleChange)="showBmtAlerts = false" heading="BMT General Alerts"
    maxWidth="80%">
    <bmt-alerts [bmtAlerts]="bmtAlerts" [bmtAlertErrors]="bmtAlertErrors"></bmt-alerts>
  </rs-dialog>
</ng-container>

<rs-dialog heading="User Inactivity" [visible]="!!countdown" (visibleChange)="countdown = 0">
  <p class="message">You have been inactive for {{inactivityDisplay | date:'mm \'minute(s)\' ss\'second(s)\''}}. We will auto log you out in {{countdownTotalInSeconds - countdown}} seconds.<br><br>To extend your
    session click anywhere.</p>
</rs-dialog>

<iframe [src]="refreshUrl" *ngIf="refreshUrl"></iframe>
<p *ngIf="usersId" class="dynaTraceUserTag" id="dynaTraceUserTagBMT" hidden>{{usersId}}</p>

<div>
  <rs-notification
    class="pay-notification"
    type="info"
    [toast]="{right: 20, top: 110}"
    [visible]="notificationService.isShowNotification"
    displayTimeout="60000"
    closeable
    (visibleChange)="notificationService.isShowNotification = false">
    <p class="pay-notification-content">
      This is a reminder that accounts are soon to be due or overdue.<br/>
      Please navigate to the Billing section and review your invoices.<br/><br/>
      <span>If you are on autopay, please disregard this message.</span>
    </p>
  </rs-notification>
</div>
