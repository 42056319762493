import { IMemberState } from './state';

export const getSelectedSubscriber = (state: { member: IMemberState }) => state.member.selectedSubscriber;
export const getAddMemberFeed = (state: { member: IMemberState }) => state.member.addMemberFeed;
export const getDependentInfo = (state: { member: IMemberState }) => {
  return {
    dependentAdded: state.member.dependentAdded,
    value: state.member.selectedDependent,
  };
};
