import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserAdminComponent } from './user-admin.component';
import { CreateUserComponent } from './create-user/create-user.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { ICreateManageUserState, initialCreateManageUserState } from '../store/createManageUser/state';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';


@NgModule({
  declarations: [UserAdminComponent, CreateUserComponent, ManageUserComponent],
  imports: [
    CommonModule,
    ButtonModule,
    InputModule,
    ReactiveFormsModule,
    LoaderModule,
    FormsModule,
    DialogModule,
    PaginatorModule,
    RouterModule
  ]
})

export class UserAdminModule {
  constructor(lux: Lux<{ createManageUserState: ICreateManageUserState }>) {
    const createManageUserState = JSON.parse(sessionStorage.getItem('createManageUserState'));
    lux.register('createManageUserState', createManageUserState ? createManageUserState : initialCreateManageUserState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('createManageUserState', JSON.stringify(state.createManageUserState)));
  }
}
