import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConfig } from '../shared/app.config';
import { IClientPlanDetails } from '../store/massActions/state';
import { matchSsns } from '../utils/validations/field-rules';
import moment from 'moment';


@Component({
  templateUrl: './family-enrollment.component.html',
  styleUrls: ['./family-enrollment.component.scss']
})
export class FamilyEnrollmentComponent implements OnInit {
  familyEnrollFeatures: string[] = [];
  selectedAdminPlan: string;
  familyEnrollDefaults: {
    asOf: string;
    clientId: string;
    eligibilityEffectiveDate: string;
    eligibilityStatus: string;
    eligibilityStatusReason: string;
    memberBenefitType: string;
    memberId: string;
    planAcronym: string;
    qualifierType: string;
    receivedDate: string;
    subClientId: string;
    subscriberEnrollmentID: string;
  };
  clientDetails: IClientPlanDetails;
  region: string;
  addMemberForm: UntypedFormGroup;
  setDefaultsForm: UntypedFormGroup;
  changeType = 'text';
  showCopyError: boolean;
  displaySsnForm: boolean;
  findMemberFeed: { planAcronym: string; clientId: string; subClientId: string; memberId: any; adminPlan: string; };
  @Output() setMemberStore: EventEmitter<any> = new EventEmitter();
  eligStatusReasons = [];
  qualifierTypes = [];
  memberBenefitTypes = [];
  contractResposnse: IClientPlanDetails;


  constructor(private config: AppConfig, private route: Router, private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.region = this.config.region;
  }

  cancelFamilyEnrollment() {
    const stateObj = {
      addMemberFeed: {
        planAcronym: '',
        clientId: '',
        subClientId: '',
        asOf: '',
        memberId: '',
        subscriberEnrollmentID: '',
        eligibilityEffectiveDate: '',
        receivedDate: '',
        qualifierType: '',
        memberBenefitType: '',
        eligibilityStatusReason: '',
        eligibilityStatus: ''
      },
      event: 'elig-member-search'
    };
    this.setMemberStore.emit(stateObj);
    this.route.navigate(['/eligibility/member']);
  }

  wasEnrollmentSuccess(output) {
    if (output === 'reset') {
      this.route.navigate(['/eligibility/family-enrollment']);
    }
    if (output === 'close') {
      this.cancelFamilyEnrollment();
    }
    if (output === 'add') {
      this.displaySsnForm = true;
      this.setFamilyEnrollDefaultsForm();
      this.setConfirmSsnForm();
    }
  }

  setConfirmSsnForm() {
    this.addMemberForm = this.fb.group({
      ssn: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(9), Validators.maxLength(9), Validators.required]],
      confirmSsn: ['', [Validators.pattern('^[0-9]*$'), matchSsns('ssn', 'confirmSsn'), Validators.minLength(9), Validators.maxLength(9), Validators.required]]
    });
    this.addMemberForm.valueChanges.subscribe(formVal => {
      this.changeType = formVal.ssn && formVal.ssn.length >= 9 ? 'password' : 'text';
    });
  }

  setFamilyEnrollDefaultsForm() {
    this.setDefaultsForm = this.fb.group({
      receivedDate: { value: moment(this.familyEnrollDefaults.receivedDate).format('YYYY-MM-DD'), disabled: true },
      eligibilityEffectiveDate: [this.familyEnrollDefaults.eligibilityEffectiveDate, Validators.required],
      eligibilityStatus: ['active', Validators.required],
      eligibilityStatusReason: [this.familyEnrollDefaults.eligibilityStatusReason, Validators.required],
      qualifierType: this.familyEnrollDefaults.qualifierType,
      memberBenefitType: this.familyEnrollDefaults.memberBenefitType
    });
  }

  onKeyPress($event: KeyboardEvent) {
    if (($event.ctrlKey || $event.metaKey) && $event.key === '67') {
      $event.preventDefault();
      this.showCopyError = true;
      setTimeout(() => {
        this.showCopyError = false;
      }, 5000);
    }
  }

  triggerFindMember() {
    this.findMemberFeed = {
      planAcronym: this.familyEnrollDefaults.planAcronym,
      clientId: this.familyEnrollDefaults.clientId,
      subClientId: this.familyEnrollDefaults.subClientId,
      memberId: this.addMemberForm.value.confirmSsn,
      adminPlan: this.selectedAdminPlan
    };
  }

  foundMember(event) {
    if (event === 'cancel') {
      this.cancelFamilyEnrollment();
    } else {
      this.setAddMemberFeed(event);
    }
  }

  setAddMemberFeed(event) {
    const stateObj = {
      addMemberFeed: {
        planAcronym: this.familyEnrollDefaults.planAcronym,
        clientId: this.familyEnrollDefaults.clientId,
        subClientId: this.familyEnrollDefaults.subClientId,
        asOf: this.familyEnrollDefaults.asOf,
        memberId: this.addMemberForm.value.confirmSsn,
        subscriberEnrollmentID: '',
        eligibilityEffectiveDate: this.setDefaultsForm.value.eligibilityEffectiveDate,
        receivedDate: this.setDefaultsForm.value.receivedDate,
        qualifierType: this.setDefaultsForm.value.qualifierType,
        memberBenefitType: this.setDefaultsForm.value.memberBenefitType,
        eligibilityStatusReason: this.setDefaultsForm.value.eligibilityStatusReason,
        eligibilityStatus: this.setDefaultsForm.value.eligibilityStatus
      },
      event: event,
      ...(typeof event !== 'string') && {
        selectedSubscriber: {
          firstName: event.name.first,
          lastName: event.name.last
        }
      }
    };
    this.setMemberStore.emit(stateObj);
  }

}
