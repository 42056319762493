import { NgModule } from '@angular/core';
import { ClientSearchModule as CSLibModule } from '@roosevelt/client-ui-lib';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ManageEligibilityComponent } from './manage-eligibility.component';
import { ManageEligibilityContainer } from './manage-eligibility.container';
import { IMassActionState, initialMassActionState } from '../store/massActions/state';
import { FindMemberModule, MemberSearchModule } from '@roosevelt/elig-ui-lib';
import { IMemberState, initialMemberState } from '../store/member/state';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import { CheckboxModule } from '@roosevelt/common-ui-lib/checkbox';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';

@NgModule({
  declarations: [
    ManageEligibilityContainer,
    ManageEligibilityComponent,
  ],
  imports: [
    CSLibModule,
    CommonModule,
    SelectModule,
    FormsModule,
    MemberSearchModule,
    ReactiveFormsModule,
    CheckboxModule,
    ButtonModule,
    DialogModule,
    LoaderModule,
    InputModule,
    RouterModule,
    FindMemberModule
  ]
})
export class ManageEligibilityModule {
  constructor(lux: Lux<{ massAction: IMassActionState, member: IMemberState }>) {
    const msState = JSON.parse(sessionStorage.getItem('mass-action-state'));
    const memberState = JSON.parse(sessionStorage.getItem('member-state'));
    lux.register('massAction', msState ? msState : initialMassActionState);
    lux.register('member', memberState ? memberState : initialMemberState);
    lux.allChanges().subscribe(state => {
      sessionStorage.setItem('mass-action-state', JSON.stringify(state.massAction));
      sessionStorage.setItem('member-state', JSON.stringify(state.member));
    });
  }
}
