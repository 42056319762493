import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../../shared/app.config';
import { BasicAuthConfig } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsApiService {

  constructor(private http: HttpClient, private config: AppConfig, private internalAuth: BasicAuthConfig) {
  }

  getBmtAlerts(req) {
    return this.http.post<any>(this.config.bmtAlertsUrl + '?limit=25&offset=0&sort=updatedOn,priority', req);
  }

  getPaymentDueNotification() {
    return this.http.get<any>(this.config.getPaymentDueNotification, {observe: 'response'});
  }
}
