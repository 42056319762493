import { IBmtBillingState } from './state';

export const initialStateMaBillingState: IBmtBillingState = {
  isFetching: false,
  achPaymentError: '',
  ebillRequest: {
    ebillUserContext: {
      payer: '',
      groupIdentifier: '',
      subgroupIdentifier: ''
    }
  },
  ebillError: '',
  isFetchingAch: false,
  achPayError: ''
};
