import { Injectable } from '@angular/core';
import { ILoginState, ISolutionsState } from './state';
import { LoginApiService } from './login-api.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class LoginAsyncService {

  constructor(private lux: Lux<{ login: ILoginState, solutionsLogin: ISolutionsState }>, private loginService: LoginApiService) {
  }

  getAuthInfo() {
    return of('').pipe(
      tap(() => this.lux.set(state => state.login, { isFetchingAuthInfo: true })),
      mergeMap(() => this.loginService.getAuthInfo()),
      tap(x => this.lux.set(state => state.login, { authInfo: x.body, isFetchingAuthInfo: false })),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.login, { isFetchingAuthInfo: false });
        this.lux.set(state => state.solutionsLogin, { isLoginFailed: true });

        return of(errorMessage);
      })
    );
  }

  // getLoggedInUserRole() {
  //   return this.lux.get(getAdminRoleReq).pipe(
  //     first(),
  //     tap(() =>
  //       this.lux.set(state => state.createManageUserState, {
  //         isFetchingUsers: true,
  //         errorsFetchingUsers: ''
  //       })),
  //     mergeMap(req => this.createManageUser.getUsersForAdmin(req)),
  //     tap(response => {
  //       this.lux.set(state => state.createManageUserState, {
  //         isFetchingUsers: false,
  //         errorsFetchingUsers: '',
  //         adminRole: response.users
  //       });
  //       this.lux.set(state => state.createManageUserState.manageUser, {
  //         adminRole: response && response.users.length ? response.users[0].roleName : ''
  //       });
  //     }),
  //     catchError(err => {
  //       const errorMessage = (err &&
  //         err.error &&
  //         err.error.apiErrorList &&
  //         err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
  //       this.lux.set(state => state.createManageUserState, {
  //         isFetchingUsers: false,
  //         errorsFetchingUsers: errorMessage
  //       });
  //       return of(err);
  //     })
  //   );
  // }

  // getLoggedInUserContext(level, groupOrSubgroupAdmin) {
  //   return of('').pipe(
  //     first(),
  //     tap(() =>
  //       this.lux.set(state => state.createManageUserState.adminClientsInfo, {
  //         fetchingClients: true,
  //         errors: '',
  //         adminClients: []
  //       })),
  //     mergeMap(req => this.createManageUser.getLoggedInUserContext(level)),
  //     tap((response: IAdminClients[]) => {
  //       response = response.filter(context => context.PLAN !== 'INVLDCTX' || groupOrSubgroupAdmin && context.CLIENT !== 'INVLDCTX');
  //       this.lux.set(state => state.createManageUserState.adminClientsInfo, {
  //         fetchingClients: false,
  //         errors: '',
  //         adminClients: response
  //       });
  //       if (response.length) {
  //         this.lux.set(state => state.createManageUserState.manageUser, {
  //           contextOfAdmin: (response.length && groupOrSubgroupAdmin) ? response[0].ADMINPLAN + '/' + response[0].PLAN + '/' + response[0].CLIENT : response[0].ADMINPLAN
  //         });
  //       } else {
  //         // If user doesn't have plans assigned redirect to login and show error message
  //         sessionStorage.clear();
  //         this.lux.set(state => state.solutionsLogin, {
  //           noContext: "You don't have plan assigned, contact your admin for access"
  //         });
  //         this.router.navigate(['/login']);
  //       }
  //     }),
  //     catchError(err => {
  //       const errorMessage = (err &&
  //         err.error &&
  //         err.error.apiErrorList &&
  //         err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
  //       this.lux.set(state => state.createManageUserState.adminClientsInfo, {
  //         fetchingClients: false,
  //         errors: errorMessage,
  //         adminClients: []
  //       });
  //       return of(err);
  //     })
  //   );
  // }
}
