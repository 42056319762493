import { Component, Input, OnInit } from '@angular/core';
import { IGeneralAlerts } from '../store/alerts/state';

@Component({
  selector: 'bmt-alerts',
  templateUrl: './bmt-alerts.component.html',
  styleUrls: ['./bmt-alerts.component.scss']
})
export class BMTAlertsComponent implements OnInit {
  page = 'create';
  @Input() bmtAlerts: IGeneralAlerts[] = [];
  @Input() bmtAlertErrors = '';

  constructor() {
  }

  ngOnInit(): void {
  }

}
