import { Injectable } from '@angular/core';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { ClientKnowledgeService } from './api.service';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IClientKnowledgeState } from './state';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class ClientKnowledgeAsync {
  constructor(
    private lux: Lux<{ clientKnowledgeState: IClientKnowledgeState }>,
    private clientKnowledgeService: ClientKnowledgeService,
  ) { }

  getClientKnowledgeURLKey(): Observable<any> {
    return of('').pipe(
      tap(() => this.lux.set(state => state.clientKnowledgeState, { error: '', isFetching: true })),
      mergeMap(x => this.clientKnowledgeService.getClientKnowledgeURLKey()),
      tap((response) => {
        if (response.status === 200 && this.isValidHttpUrl(response.body)) {
          this.lux.set(state => state.clientKnowledgeState, { isFetching: false });
        } else {
          console.error(response.body); /* logging the error on console but displaying Internal system error on UI*/
          this.lux.set(state => state.clientKnowledgeState, {
            error: 'Internal System Error. Please try again by clicking on the Client Knowledge Reports link',
            isFetching: false
          });
        }
      })
      , map(response => response.body)
      , catchError((err: HttpErrorResponse) => {
        let errorMessage = err.message;
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(error => error.errorMessage).join('\n');
        }
        this.lux.set(state => state.clientKnowledgeState, { error: errorMessage, isFetching: false });
        return of(errorMessage);
      })
    );
  }

  isValidHttpUrl(string) {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }
    return url.protocol === 'http:' || url.protocol === 'https:';
  }
}
