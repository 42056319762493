import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public isShowNotification = false;

  setNotificationState(state: boolean): void {
    this.isShowNotification = state;
  }
}
