import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FamilyEnrollmentComponent } from './family-enrollment.component';
import { EnrollFamilyModule, FindMemberModule } from '@roosevelt/elig-ui-lib';
import { FamilyEnrollmentContainer } from './family-enrollment.container';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import { DialogModule } from '@roosevelt/common-ui-lib/dialog';

@NgModule({
  declarations: [
    FamilyEnrollmentContainer,
    FamilyEnrollmentComponent
  ],
  imports: [
    CommonModule,
    EnrollFamilyModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DialogModule,
    InputModule,
    ButtonModule,
    FindMemberModule,
    SelectModule
  ]
})
export class FamilyEnrollmentModule {
}
