import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { IClient, IClients } from '@roosevelt/client-ui-lib/lib/client-search/store/state';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, startWith } from 'rxjs/operators';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import {
  errorFetchingAuthsToEdit,
  errorsFetchingClients,
  errorsInUpdatingAuths,
  fetchingClients,
  getAdminAndUserAuthsResposne,
  getAdminClients,
  getClientsOfAdmin,
  getManageUserAuthsResp,
  isFetchingAuthsToEdit,
  updatingAuthInProgress
} from 'src/app/store/createManageUser/selector';
import {
  IAdminAndUserAuthResponse,
  IAdminAndUserAuthsReq,
  IAdminClients,
  ICreateManageUserState,
  IManageuser,
  IManageUserAuthsReq,
  initialCreateManageUserState
} from 'src/app/store/createManageUser/state';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'bmt-manage-auth-at-multi-group',
  templateUrl: './multi-group-level.component.html',
  styleUrls: ['./multi-group-level.component.scss']
})
export class ManageAuthAtMutiGroupComponent implements OnInit, OnDestroy {
  // @Input() selectedUserId: string;
  // @Input() adminRole: string;
  // @Input() userContext: string;
  @Input() userInfo: IManageuser;
  @Output() cancelGroupAuthEdit: EventEmitter<boolean> = new EventEmitter();

  luxId = 'BMT-manage-auth-at-multi-group' + this.constructor.name + new Date().toISOString();
  getErrors$: Observable<string> = this.lux.get(errorFetchingAuthsToEdit, this.luxId);
  isFetchingAuthsToEdit$: Observable<boolean> = this.lux.get(isFetchingAuthsToEdit, this.luxId);
  getUserAuthsToEdit$: Observable<IAdminAndUserAuthResponse> = this.lux.get(getAdminAndUserAuthsResposne, this.luxId);

  getAdminClients$: Observable<IAdminClients[]> = this.lux.get(getAdminClients, this.luxId);

  errorsInUpdatingAuths$: Observable<string> = this.lux.get(errorsInUpdatingAuths, this.luxId);
  updatingAuthInProgress$: Observable<boolean> = this.lux.get(updatingAuthInProgress, this.luxId);
  updatingAuthsDone$: Observable<string> = this.lux.get(getManageUserAuthsResp, this.luxId);

  getClientsOfAdmin$: Observable<IClients> = this.lux.get(getClientsOfAdmin, this.luxId);
  errorsFetchingClients$: Observable<string> = this.lux.get(errorsFetchingClients, this.luxId);
  fetchingClients$: Observable<boolean> = this.lux.get(fetchingClients, this.luxId);

  isFetchingUserList: boolean;
  editAuthorizations = false;
  errors: string;
  fetchingAuths: boolean;
  userAndAdminAuths: IAdminAndUserAuthResponse;
  adminClientsInfo: IClient[];

  selectedOption = '';
  selectedClient: any = null;
  updatingAuths: boolean;
  updatingAuthsResp: string;
  updatingAutherrors: string;
  eligAccessLevelOptions = [{
    label: 'Update',
    value: 'Update'
  }, {
    label: 'Inquiry',
    value: 'Inquiry'
  }, {
    label: 'None',
    value: 'None'
  }];

  satAccessLevelOptions = [{
    label: 'Update',
    value: 'Update'
  }, {
    label: 'Inquiry',
    value: 'Inquiry'
  }, {
    label: 'None',
    value: 'None'
  }];

  forNewlyCreatedUser = [{
    eligibilityAccessLevel: '',
    clientKnowledgeIndicator: false,
    billingReportAccessIndicator: false,
    customClientReportAccessIndicator: false,
    claimDetailReportAccessIndicator: false,
    satAccessLevel: '',
    satAccessReportIndicator: false
  }];

  editAllGroupsForm: UntypedFormGroup;
  initialSnapshot: any;
  initailAdminUserAuthResponse = initialCreateManageUserState.adminAndUserAuths;
  authsModifiedForAllGroups: boolean;
  plansOfAdmin: string[];
  userInfoForPopUp: IManageuser;
  errorsFetchingClients: string;
  fetchingClients: boolean;

  totalRecords: number = 0;
  // currentPage = 1;
  defaultPageSize = 25;
  selectedIndex = 0;
  adminOnly = ['BMT-AGENCY-ADMIN', 'BMT-CLIENT-ASSOCIATION-ADMIN', 'BMT-AGENT-ADMIN'];
  alreadyUser: boolean;

  constructor(private fb: UntypedFormBuilder,
              private router: Router,
              private lux: Lux<{ createManageUserState: ICreateManageUserState }>,
              private createManageUserAsync: CreateManageUserAsync) {
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
    this.clearTheAdminUserState();
  }

  clearTheAdminUserState() {
    this.lux.set(state => state.createManageUserState.manageUserAuths, {authResponse: '', errors: ''});
    this.lux.set(state => state.createManageUserState.adminAndUserAuths, {
      authResponse : this.initailAdminUserAuthResponse.authResponse,
      errors: ''
    });
  }

  ngOnInit(): void {
    // this.plansOfAdmin = this.userInfo.planAcronyms;
    // this.plansOfAdmin = clientsInfo.filter(adminClients => adminClients.CLIENT).map(plan => plan.PLAN);
    // console.log(this.plansOfAdmin);
    // this.getClientsOfAdmin$.subscribe(clientsInfo => {
    //   this.adminClientsInfo = clientsInfo.clients;
    //   this.totalRecords = clientsInfo.pagination.totalRecords;
    // })
    this.alreadyUser = (this.userInfo.selectedUserType.toLowerCase() !== 'delegate');
    this.errorsFetchingClients$.subscribe(errs => this.errorsFetchingClients = errs);
    this.fetchingClients$.subscribe(fetching => this.fetchingClients = fetching);
    this.errorsInUpdatingAuths$.subscribe(errors => this.updatingAutherrors = errors);
    this.updatingAuthInProgress$.subscribe(inprogress => {
      this.selectedOption && this.selectedOption !== 'customizeGroups' && (this.updatingAuths = inprogress);
    });
    this.updatingAuthsDone$.subscribe(res => {
      this.selectedOption && this.selectedOption !== 'customizeGroups' && (this.updatingAuthsResp = res);
      if (res) {
        this.authsModifiedForAllGroups = false;
        this.alreadyUser = (this.selectedOption !== 'delegate');
        this.lux.set(state => state.createManageUserState.manageUser, {selectedUserType: (this.userInfo.selectedUserType.toLocaleLowerCase() !== 'delegate' && this.selectedOption === 'delegate') ? 'Delegate' : 'User'});
      }
    });

    this.getErrors$.subscribe(err => this.errors = err);
    this.isFetchingAuthsToEdit$.subscribe(fetchingAuths => this.fetchingAuths = fetchingAuths);
    this.getUserAuthsToEdit$.subscribe((adminAndUserAuths: IAdminAndUserAuthResponse) => {
      this.eligAccessLevelOptions = adminAndUserAuths.adminRelatedAuthorizations.eligibilityAccessLevel === 'Inquiry' ? this.eligAccessLevelOptions.filter(option => option.value !== 'Update') : adminAndUserAuths.adminRelatedAuthorizations.eligibilityAccessLevel === 'None' ? this.eligAccessLevelOptions.filter(option => option.value === 'None') : this.eligAccessLevelOptions;
      this.satAccessLevelOptions = adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel && adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel === 'Inquiry' ? this.satAccessLevelOptions.filter(option => option.value !== 'Update') : adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel && adminAndUserAuths.adminRelatedAuthorizations.satAccessLevel === 'None' ? this.satAccessLevelOptions.filter(option => option.value === 'None') : this.satAccessLevelOptions;

      if (!adminAndUserAuths.userRelatedAuthorizations || (adminAndUserAuths.userRelatedAuthorizations && !adminAndUserAuths.userRelatedAuthorizations.length)) {
        adminAndUserAuths['userRelatedAuthorizations'] = this.forNewlyCreatedUser;
      }
      this.userAndAdminAuths = adminAndUserAuths;
      if (adminAndUserAuths.userRelatedAuthorizations.length && this.selectedOption === 'allGroups') {
        this.createEditAllGorupsForm();
      }
    });
  }

  fetchUserAuthorizations() {
    const userContextStr = this.userInfo.contextOfAdmin.split('/');
    const adminAndUserAuthReq: IAdminAndUserAuthsReq = {
      userIdentifier: this.userInfo.selectedUserId,
      payer: userContextStr[1],
      payerAdmin: userContextStr[0],
      groupAccessLevel: 'all',
      groupSpecifiedIdentifier: userContextStr[2],
      ...userContextStr[3] && {subGroupSpecifiedIdentifier: userContextStr[3]}
    };
    this.lux.set(state => state.createManageUserState.adminAndUserAuths, {authRequest: adminAndUserAuthReq});
    this.createManageUserAsync.getAdminAndUserAuths('edit').subscribe();
  }

  manageUserAuthFor(option) {
    this.selectedOption = option;
    (option === 'customizeGroups') && this.getClients(0);
    (option === 'allGroups') ? this.fetchUserAuthorizations() : this.clearTheAdminUserState();
  }

  toggleTheClient(client: IClient) {
    this.userInfoForPopUp = {
      ...this.userInfo,
      contextOfAdmin: this.userInfo.contextOfAdmin.split('/')[0] + '/' + client.planAcronym + '/' + client.specifiedId
    };
    this.selectedClient = client;
  }

  navigateToUserAdmin() {this.router.navigate(['userAdmin/manage']); }

  createEditAllGorupsForm() {

    const adminAuths = this.userAndAdminAuths.adminRelatedAuthorizations;
    const userAuths = this.userAndAdminAuths.userRelatedAuthorizations[0];
    this.editAllGroupsForm = this.fb.group({
      // adminEligAccess: adminAuths.eligibilityAccessLevel,
      adminEligAccess: '',
      adminClientKnowledge: false,
      adminCustomClientReport: false,
      adminBillingReport: false,
      adminClientDetailReport: false,
      // adminSatAccess: adminAuths.satAccessLevel || 'None',
      adminSatAccess: '',
      userAuthForm: this.fb.group({
        ...adminAuths.eligibilityAccessLevel && {userEligAccess: userAuths.eligibilityAccessLevel || 'None'},
        ...adminAuths.clientKnowledgeIndicator && {userClientKnowledge: userAuths.clientKnowledgeIndicator || false},
        ...adminAuths.customClientReportAccessIndicator && {userCustomClientReportAccess: userAuths.customClientReportAccessIndicator || false},
        ...adminAuths.billingReportAccessIndicator && {userBillingReport: userAuths.billingReportAccessIndicator || false},
        ...adminAuths.claimDetailReportAccessIndicator && {userClientDetailReport: userAuths.claimDetailReportAccessIndicator || false},
        ...adminAuths.satAccessLevel && {userSatAccess: userAuths.satAccessLevel || 'None'}
      })
    });
    const editFromContrls = this.editAllGroupsForm.controls;
    const userAuthForm = this.editAllGroupsForm.controls.userAuthForm as UntypedFormGroup;
    const userAuthFormContrls = this.editAllGroupsForm.controls.userAuthForm['controls'];
    this.initialSnapshot = JSON.stringify(userAuthForm.getRawValue());
    editFromContrls.adminEligAccess.valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe(x => {
      userAuthFormContrls.userEligAccess.setValue(x);
    });
    editFromContrls.adminClientKnowledge.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userClientKnowledge.setValue(x);
    });
    editFromContrls.adminCustomClientReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userCustomClientReportAccess.setValue(x);
    });
    editFromContrls.adminBillingReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userBillingReport.setValue(x);
    });
    editFromContrls.adminClientDetailReport.valueChanges.pipe(distinctUntilChanged()).subscribe(x => {
      userAuthFormContrls.userClientDetailReport.setValue(x);
    });
    editFromContrls.adminSatAccess.valueChanges.pipe(distinctUntilChanged(), filter(x => !!x)).subscribe(x => {
      userAuthFormContrls.userSatAccess.setValue(x);
    });
    userAuthForm.valueChanges.pipe(startWith(userAuthForm.getRawValue())).subscribe(val => {
      this.authsModifiedForAllGroups = (this.userInfo.selectedUserType.toLowerCase() === 'delegate') || (this.initialSnapshot !== JSON.stringify(userAuthForm.getRawValue()));
    });
  }

  manageAuthorizations() {
    // const userContextStr = this.userInfo.contextOfAdmin.split('/');
    const manageUserReq: IManageUserAuthsReq = {
      userIdentifier: this.userInfo.selectedUserId,
      userType: this.selectedOption !== 'delegate' ? 'user' : 'delegate'
    };
    if (this.selectedOption === 'allGroups') {
      const authFormValues = (this.editAllGroupsForm.controls.userAuthForm as UntypedFormGroup).getRawValue();
      manageUserReq.authorizations = [
        {
          payers: this.userInfo.planAcronyms,
          groupAccessLevel: 'all',
          subgroupAuthorizations: [
            {
              eligibilityAccessLevel: authFormValues.userEligAccess,
              satAccessLevel: authFormValues.userSatAccess,
              clientKnowledgeIndicator: authFormValues.userClientKnowledge,
              customClientReportAccessIndicator: authFormValues.userCustomClientReportAccess,
              billingReportAccessIndicator: authFormValues.userBillingReport,
              claimDetailReportAccessIndicator: authFormValues.userClientDetailReport
            }
          ]
        }
      ];
    }
    this.triggerUserAuthModify(manageUserReq);
  }

  triggerUserAuthModify(manageUserReq) {
    this.lux.set(state => state.createManageUserState.manageUserAuths, {authRequest: manageUserReq});
    this.createManageUserAsync.manageUserAuthorizations().subscribe();
  }

  getClients(offset) {
    const req = {
      planAcronym: this.userInfo.planAcronyms,
      offset: offset,
      limit: this.defaultPageSize
    };
    this.createManageUserAsync.getClients(req).subscribe(clientsInfo => {
      if (clientsInfo) {
        this.adminClientsInfo = clientsInfo.clients;
        this.totalRecords = clientsInfo.pagination ? clientsInfo.pagination.totalRecords : 0;
        // Move the user to top view
        const element = document.querySelector('#scrollId');
        element.scrollIntoView();
      }
    });
  }

  getMoreClients(page) {
    // this.currentPage = page.page;
    this.getClients(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize) : 0);
  }
}
