import { IAuthInfo, ILoginState, ISolutionsState, IUserAuthorizations } from './state';

export const isAuthenticated = (state: { solutionsLogin: ISolutionsState }) => state.solutionsLogin.isAuthenticated;

export const isLoginFailed = (state: { solutionsLogin: ISolutionsState }) => state.solutionsLogin.isLoginFailed;

export const getUsersName = (state: { login: ILoginState }): string => state.login.authInfo.firstName + ' ' + state.login.authInfo.lastName;

export const getUserAuths = (state: { login: ILoginState }): { [key: string]: string[] } => state.login.userAuthorizations.userAdminPlansAndPlans;

export const getUserInfo = (state: { login: ILoginState }): IAuthInfo => state.login.authInfo;

export const getSelectedAdminPlan = (state: { login: ILoginState }): string => state.login.selectedAdminPlan;

export const noAuthorizations = (state: { solutionsLogin: ISolutionsState }) => state.solutionsLogin.noAuthroziations;

export const userHasMoreRoles = (state: { solutionsLogin: ISolutionsState }) => state.solutionsLogin.moreUserRoles;

export const userLoginInProgress = (state: { login: ILoginState }) => state.login.isFetchingAuthInfo;

export const getImpersonationUser = (state: { login: ILoginState }) => state.login.authInfo.impersonationUser;

export const getTimeOutError = (state: { solutionsLogin: ISolutionsState }) => state.solutionsLogin.timeOutError;

export const getContextError = (state: { solutionsLogin: ISolutionsState }) => state.solutionsLogin.noContext;

export const getSessionId = () => sessionStorage.getItem('sessionId');
