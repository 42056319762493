import { Injectable } from '@angular/core';
import { catchError, defaultIfEmpty, first, map, mergeMap, tap } from 'rxjs/operators';
import { AlertsApiService } from './api.service';
import { Observable, of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { IAlertsState } from './state';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsAsync {
  constructor(
    private lux: Lux<{ bmtAlertsState: IAlertsState}>,
    private alertsService: AlertsApiService,
  ) { }

  getBMTAlerts(req) {
      return of(req).pipe (
      first(),
      tap(() =>
        this.lux.set(state => state.bmtAlertsState, { isFetchingAlerts: true })),
      mergeMap(x => this.alertsService.getBmtAlerts(req)),
      tap(results => {
        this.lux.set(state => state.bmtAlertsState, { alerts: results, isFetchingAlerts: false });
      }),
      catchError((err: HttpErrorResponse) => {
        let errorMessage = '';
        if (err && err.error && err.error.apiErrorList && err.error.apiErrorList.length > 0) {
          errorMessage = err.error.apiErrorList.map(err => err.errorMessage).join('\n');
        }
        this.lux.set(state => state.bmtAlertsState, { errors: errorMessage, isFetchingAlerts: false });
        return of(errorMessage);
      })
    );
  }

  getPaymentDueNotification(): Observable<boolean> {
    return this.alertsService.getPaymentDueNotification().pipe(
      tap(() => {
        this.lux.set(state => state.bmtAlertsState, {isFetchingAlerts: true});
      }),
      map(response => {
        this.lux.set(state => state.bmtAlertsState, {isFetchingAlerts: false});
        return response.status === 200;
      }),
      defaultIfEmpty(false),
      catchError(err => {
        const errorMessage = (err &&
          err.error &&
          err.error.apiErrorList &&
          err.error.apiErrorList.length > 0) ? err.error.apiErrorList.map(item => item.errorMessage).join('\n') : err.name && err.name === 'TimeoutError' ? 'Timeout has occurred' : 'Internal server error';
        this.lux.set(state => state.bmtAlertsState, {errors: errorMessage, isFetchingAlerts: false});
        return of(false);
      })
    );
  }
}
