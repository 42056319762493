import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { BmtBillingAsync } from 'src/app/store/billing/async';
import { IBmtBillingState } from 'src/app/store/billing/state';
import { matchAccountOrRoutingNumber } from 'src/app/utils/validations/field-rules';
import moment from 'moment';
import { Observable } from 'rxjs';
import { getAchError, isFetching } from 'src/app/store/billing/selector';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'ach-payment',
  templateUrl: 'ach-payment.component.html',
  styleUrls: ['ach-payment.component.scss']
})
export class AchPaymentComponent {
  luxId = 'BMT-ACHPayment-Comp' + this.constructor.name;
  @Input() groupDetails: any;
  @Input() isAllSubclientSelected: boolean;
  @Output() resetSelected = new EventEmitter<any>();
  phoneMask = { mask: '(000) 000-0000' };
  achRegistrationForm: UntypedFormGroup;
  achFormValues: any;
  accountType: any;
  dropDownCountries = [{
    label: '',
    value: ''
  }];
  dropDownStates = [{
    label: '',
    value: ''
  }];
  achError$: Observable<string> = this.lux.get(getAchError, this.luxId);
  isFetching$: Observable<string> = this.lux.get(isFetching, this.luxId);
  achError: string;
  isFetching: any;
  acknowledge = true;
  achPaymentRegistrationDetails: any;
  showConfirmation = false;
  isRegistrationSuccess = false;
  payer: string;
  achPaymentRequest: any;
  disableStates = false;
  isACHRegistrationSuccess = false;

  constructor(private fb: UntypedFormBuilder, private BBAsync: BmtBillingAsync,
              private lux: Lux<{ billingState: IBmtBillingState }>) {
  }

  ngOnInit(): void {
    this.achError$.subscribe(error => {
      this.achError = error;
    });
    this.isFetching$.subscribe(fetching => {
      this.isFetching = fetching;
    });
    this.setAchRegistrationForm();
    this.setCountriesDropDown();
    this.setStatesDropDown('US');
    this.achRegistrationForm.controls.country.valueChanges.pipe(startWith(this.achRegistrationForm.controls.country.value)).subscribe(val => {
      if (val) {
        this.disableStates = val !== 'US';
      }
    });
  }

  setStatesDropDown(country) {
    this.BBAsync.getStates(country).subscribe(states => {
      if (states) {
        const tempAry = (states as {
          value: string;
          code: string
        }[]).map(res => {
          return {
            label: res.value,
            value: res.code
          };
        });
        this.dropDownStates = this.dropDownStates.concat(tempAry);
      }
    });
  }

  setCountriesDropDown() {
    this.BBAsync.getCountries().subscribe(countries => {
      if (countries) {
        const tempAry = (countries as {
          value: string;
          code: string
        }[]).map(res => {
          return {
            label: res.value,
            value: res.code
          };
        });
        this.dropDownCountries = this.dropDownCountries.concat(tempAry);
      }
    });

  }

  setAchRegistrationForm() {
    this.achRegistrationForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.maxLength(50)]],
      bankName: ['', [Validators.pattern('^[0-9a-zA-Z- ]*$'), Validators.required, Validators.maxLength(50)]],
      line1: ['', [Validators.required, Validators.maxLength(50)]],
      line2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      country: ['US', [Validators.required]],
      zip: ['', [Validators.required, Validators.pattern('^[0-9]{5}?$')]],
      accountName: ['', [Validators.required, Validators.pattern('^[a-zA-Z.\'-]+( +[a-zA-Z.\'-]+)*$'), Validators.maxLength(50)]],
      accountNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5), matchAccountOrRoutingNumber('accountNumber', 'confirmAccountNumber')]],
      confirmAccountNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(5), matchAccountOrRoutingNumber('accountNumber', 'confirmAccountNumber')]],
      routingNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), matchAccountOrRoutingNumber('routingNumber', 'confirmRoutingNumber')]],
      confirmRoutingNumber: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(9), matchAccountOrRoutingNumber('routingNumber', 'confirmRoutingNumber')]],
      effectiveDate: ['', Validators.required],
      phoneNumber: ['', [Validators.required]],
      emailAddress: ['', [Validators.required, Validators.maxLength(100), Validators.pattern('^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$')]],
    });

  }

  achPaymentsDetails() {
    this.achFormValues = this.achRegistrationForm.value;
    const address = this.achFormValues.line1 + ',' + this.achFormValues.line2 + ',' + this.achFormValues.city + ',' + this.achFormValues.state + ',' + this.achFormValues.country + ',' + this.achFormValues.zip;
    this.payer = this.isAllSubclientSelected ? this.groupDetails.planAcronym : this.groupDetails[0].client.planAcronym,
      // Delete below request object and related state and api calls once peoplesoft team implements ach get call
      this.achPaymentRegistrationDetails = {
        payer: this.payer,
        groupSpecifiedIdentifier: this.isAllSubclientSelected ? this.groupDetails.specifiedId : this.groupDetails[0].client.specifiedId,
        groupName: this.isAllSubclientSelected ? this.groupDetails.name : this.groupDetails[0].client.name,
        subgroupSpecifiedIdentifiers: this.isAllSubclientSelected ? ['all'] : this.groupDetails.map(x => x.specifiedId),
        effectiveDate: moment(this.achFormValues.effectiveDate).format('YYYY-MM-DD'),
        phoneNumber: this.formatPhoneNumber(this.achFormValues.phoneNumber),
        emailAddress: this.achFormValues.emailAddress,
        firstName: this.achFormValues.firstName,
        lastName: this.achFormValues.lastName,
        bankAccountDetails: {
          accountType: this.accountType,
          nameOnAccount: this.achFormValues.accountName,
          bankName: this.achFormValues.bankName,
          accountNumber: this.achFormValues.accountNumber,
          routingNumber: this.achFormValues.routingNumber,
          address: address
        }
      };
    this.achPaymentRequest = {
      plan: this.isAllSubclientSelected ? this.groupDetails.planAcronym : this.groupDetails[0].client.planAcronym,
      asOfDate: moment(this.achFormValues.effectiveDate).format('YYYY-MM-DD'),
      groupSubGroups: {
        group: this.isAllSubclientSelected ? this.groupDetails.specifiedId : this.groupDetails[0].client.specifiedId,
        subgroups: this.isAllSubclientSelected ? ['all'] : this.groupDetails.map(x => x.specifiedId)
      },
      bankAccountDetails: {
        accountType: this.accountType,
        accountName: this.achFormValues.accountName,
        bankName: this.achFormValues.bankName,
        accountNumber: this.achFormValues.accountNumber,
        routingNumber: this.achFormValues.routingNumber,
        address: {
          line1: this.achFormValues.line1,
          line2: this.achFormValues.line2,
          city: this.achFormValues.city,
          state: this.achFormValues.state,
          country: this.achFormValues.country,
          zip: this.achFormValues.zip
        }
      },
      requesterInfo: {
        firstName: this.achFormValues.firstName,
        lastName: this.achFormValues.lastName,
        phoneNumber: this.achFormValues.phoneNumber,
        emailAddress: this.achFormValues.emailAddress,
        effectiveDate: moment(this.achFormValues.effectiveDate).format('YYYY-MM-DD'),
      }
    };
    this.showConfirmation = true;
    this.achError = '';
  }

  register(event) {
    this.BBAsync.registerAchPayment(this.achPaymentRegistrationDetails).subscribe(response => {
      if (response && response.status === 200) {
        this.isRegistrationSuccess = true;
      }
    });
    this.BBAsync.registerAchPayDetails(this.achPaymentRequest).subscribe(response => {
      if (response && response.status === 200) {
        this.isACHRegistrationSuccess = true;
      }
    });
  }

  acknowledgeSelected(event) {
    this.acknowledge = !event.target.checked;
  }

  formatPhoneNumber(phnNumber) {
    phnNumber = phnNumber.replace(/[- )(]/g, '');
    return phnNumber;
  }

  dialogClose(event) {
    if (this.isRegistrationSuccess) {
      this.resetSelected.emit(true);
      this.achRegistrationForm.reset();
    }
    this.achError = '';
    this.showConfirmation = false;
  }

  phoneNumberFormat(phnNumber) {
    phnNumber = phnNumber.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
    phnNumber = '(' + phnNumber[1] + ') ' + phnNumber[2] + '-' + phnNumber[3];
    return phnNumber;
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

}


