import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppConfig } from '../shared/app.config';
import { IAuthInfo, ILoginState } from '../login/store/state';
import { getUserInfo } from '../login/store/selectors';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { Observable } from 'rxjs';

@Component({
  templateUrl: 'billing.component.html',
  styleUrls: ['billing.component.scss'],
})
export class BillingComponent implements OnInit {
  luxId = 'bmt-billing' + this.constructor.name;
  @Output() insertEbillContext = new EventEmitter<any>();
  selectedAdminPlan: any;
  activeTab = 'Invoices';
  selectedClient: any;
  selectedSubClient: any;
  peopleSoftUrl: any;
  ebillError: string;
  isFetching: string;
  resetButton: HTMLElement;
  showClientSubclientInfo: boolean;
  subGroupInfo: any;
  allSubClients = false;
  billingFeed: any;
  userFeed: any;
  showReports: boolean;
  showPaymentHistory = false;
  billingFeatures: any;
  billingAttributeFeatures: string [];
  guard$: Observable<IAuthInfo> = this.lux.get(getUserInfo, this.luxId);
  constructor(env: AppConfig, private lux: Lux<{ login: ILoginState, authInfo: IAuthInfo }>) {
    this.peopleSoftUrl = env.peopleSoftUrl;
  }

  ngOnInit() {
    this.guard$.subscribe(info => {
      this.userFeed = {
        email: info.email,
        userName: info.id,
        appName: 'RBMT'
      };
    });
  }

  clientSelected(event) {
    this.showReports = false;
    this.showPaymentHistory = false;
    this.showClientSubclientInfo = false;
    this.selectedClient = event;
  }

  subClientSelected(subGroup) {
    this.showReports = false;
    this.showPaymentHistory = false;
    if (subGroup && subGroup.length) {
    this.selectedSubClient = subGroup[0].specifiedId;
    if (this.activeTab === 'Recurring ACH Setup' || this.activeTab === 'PaymentHistory') {
      this.subGroupInfo = subGroup;
      this.allSubClients = false;
      this.showClientSubclientInfo = true;
      this.activeTab === 'PaymentHistory' && this.constructBillingFeed(subGroup[0]);
    } else if (this.activeTab === 'EBill') {
      this.insertEbillContext.emit();
    } else if ((this.activeTab === 'Invoices') || (this.activeTab === 'Historical Reports')) {
      this.constructBillingFeed(subGroup[0]);
    }
  }
  }

  constructBillingFeed(data?) {
    this.billingFeatures = this.activeTab === 'Invoices' ? ['consolidatedInvoice', 'nonConsolidatedInvoice'] : ['historicalReports'];
    const delay = setTimeout(() => {
    if (data) {
      this.billingFeed = {
        clientId:  data.client.specifiedId,
        clientName: data.client.name,
        subClientId: data.specifiedId,
        subClientName: data.name,
        plan: data.planAcronym,
        adminPlan: this.selectedAdminPlan
      };
    } else {
      this.billingFeed = {
        clientId:  this.selectedClient.specifiedId,
        clientName: this.selectedClient.name,
        subClientId: '',
        subClientName: '',
        plan: this.selectedClient.planAcronym,
        adminPlan: this.selectedAdminPlan
      };
    }
    this.showPaymentHistory = true;
    this.showReports = true;
  }, 500);

  }

  resetSelected() {
    this.showClientSubclientInfo = false;
    this.showReports = false;
  }

  switchBillingTab(tab) {
    this.activeTab = tab;
    this.resetButton = document.querySelector(
      '.client-search-buttons .rs-button'
    );
    if (this.resetButton && this.resetButton != null) {
      this.resetButton.click();
    }
  }

  selectedAllSubClients(event) {
    if (event) {
      this.showClientSubclientInfo = !!(this.activeTab === 'Recurring ACH Setup' || this.activeTab === 'PaymentHistory' );
      this.allSubClients = true;
      this.showReports = false;
      this.constructBillingFeed();
    }
  }

}
