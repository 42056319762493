import { Injectable } from '@angular/core';
import { Lux } from '@roosevelt/common-ui-lib/core';
import { AuthorizationsService } from './authorizations.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IAppAuthorizations } from './state';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsAsync {

  constructor(private lux: Lux<{ appUiAuth: IAppAuthorizations }>, private authService: AuthorizationsService) {
  }

  getAuthorizations(authMode, context): any {
    return of('').pipe(
      tap(() => this.lux.set(state => state.appUiAuth, {
        isFetchingUiAuth: true,
        ...(authMode === 'relaxed') && {},
        ...(authMode === 'strict') && { strictMode: {} },
      })),
      mergeMap(() => this.authService.getAuthorizations(authMode, context)),
      tap(uiAuth => {

        this.lux.set(state => state.appUiAuth, {
          isFetchingUiAuth: false,
          ...(authMode === 'relaxed') && { relaxedMode: uiAuth},
          ...(authMode === 'strict') && { strictMode: uiAuth },
        })
        // if (authMode === 'relaxed') {
        //   this.lux.set(state => state.appUiAuth, { relaxedMode: uiAuth });
        // } else {
        //   this.lux.set(state => state.appUiAuth, { strictMode: uiAuth });
        // }
      }),
      catchError(err => {
        this.lux.set(state => state.appUiAuth, {
          isFetchingUiAuth: false,
          ...(authMode === 'relaxed') && { relaxedMode: {} },
          ...(authMode === 'strict') && { strictMode: {} }
        });
        return of(null);
      })
    );
  }

}
