import { Component, ComponentFactoryResolver, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { SummaryOfChangesComponent } from './summary-of-changes.component';
import { SummaryOfChangesAsync } from '../store/summaryOfChanges/async';
import { ISummaryOfChangesState, ITransactionsRes } from '../store/summaryOfChanges/state';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from '../login/store/selectors';
import { ILoginState } from '../login/store/state';
import moment from 'moment';
import { getTSearchResponse, getTSessionResponse, isFetchingUserTransactions } from '../store/summaryOfChanges/selector';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'app-summary-of-changes',
  template: '<ng-container #SummaryOfChangesComponent></ng-container>'
})

export class SummaryOfChangesContainer implements OnInit, OnDestroy {

  luxId = 'BMT-summaryOfChanges' + this.constructor.name;
  @ViewChild('SummaryOfChangesComponent', { read: ViewContainerRef, static: true })
  componentArea: ViewContainerRef;
  componentInstance: SummaryOfChangesComponent;
  selectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  isFetchingUserTransactions$: Observable<boolean> = this.lux.get(isFetchingUserTransactions, this.luxId);
  getTSessionResponse$: Observable<ITransactionsRes> = this.lux.get(getTSessionResponse, this.luxId);
  getTSearchResponse$: Observable<ITransactionsRes> = this.lux.get(getTSearchResponse, this.luxId);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private scAsync: SummaryOfChangesAsync,
    private lux: Lux<{ sChanges: ISummaryOfChangesState, login: ILoginState }>) {
  }

  ngOnInit(): void {
    this._loadComponent();
  }

  _loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      SummaryOfChangesComponent
    );
    this.componentArea.clear();
    const componentRef = this.componentArea.createComponent(componentFactory);
    this.componentInstance = componentRef.instance;
    this.componentInstance.getTBySession.subscribe(this.getTBySession.bind(this));
    this.componentInstance.getTBySearch.subscribe(this.getTBySearch.bind(this));
    this.componentInstance.getDetails.subscribe(this.getSelectedDetails.bind(this));
    this.componentInstance.getMoreRecords.subscribe(this.getMoreRecords.bind(this));
    this.componentInstance.resetSearchClick.subscribe(this.resetTBySearch.bind(this));
    this.selectedAdminPlan$.subscribe(x => {
      this.componentInstance.selectedAdminPlan = x;
    });
    this.isFetchingUserTransactions$.subscribe(x => {
      this.componentInstance.isFetching = x;
    });
    this.getTSessionResponse$.subscribe(x => {
      this.componentInstance.sessionRecords = x;
      this.componentInstance.getPagingData();
    });
    this.getTSearchResponse$.subscribe(x => {
      this.componentInstance.searchTRecords = x;
      this.componentInstance.getPagingData();
    });
  }

  getTBySession() {
    this.lux.set(state => state.sChanges.transactionsReq.pagination, { offset: 0 });
    this.scAsync.getTBySessionId().subscribe();
  }

  getTBySearch(value) {
    const req = {
      payer: this.componentInstance.selectedSubClients[0].planAcronym,
      groupSpecifiedIdentifier: this.componentInstance.selectedSubClients[0].client.specifiedId,
      subgroupSpecifiedIdentifier: this.componentInstance.selectedSubClients[0].specifiedId,
      startDate: moment(value.startDate).format('YYYY-MM-DD'),
      endDate: moment(value.endDate).format('YYYY-MM-DD')
    };
    this.lux.set(state => state.sChanges.transactionsReq, { searchCriteria: req, pagination: { offset: 0, limit: 100 } });
    this.scAsync.getTBySearch().subscribe();
  }

  getMoreRecords(offset) {
    this.lux.set(state => state.sChanges.transactionsReq.pagination, { offset: offset });
    this.componentInstance.session ? this.scAsync.getTBySessionId(true).subscribe() :
      this.scAsync.getTBySearch(true).subscribe();
  }

  resetTBySearch() {
    this.componentInstance.transactionsRecords = { totalRecords: 0, transactions: [] };
    this.lux.set(state => state.sChanges.searchTransactionsRes, { totalRecords: 0, transactions: [] });
  }

  getSelectedDetails(transaction) {
    this.lux.set(state => state.sChanges.selectedTransaction, transaction);
    this.scAsync.getMemberEligHistory().subscribe();
    this.scAsync.getCobHistory().subscribe();
    this.scAsync.getCoverageHistory().subscribe();
    this.scAsync.getPersonHistory(transaction.personId).subscribe();
    this.scAsync.getAddressHistory(transaction.personId).subscribe();
  }

  ngOnDestroy() {
    this.resetTBySearch();
    this.lux.destroy(this.luxId);
  }
}
