import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ICreateManageUserState, IManageuser } from 'src/app/store/createManageUser/state';
import { getManagingUserInfo } from '../store/createManageUser/selector';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'bmt-manage-user-auth',
  templateUrl: './manage-user-or-auth.component.html',
  styleUrls: ['./manage-user-or-auth.component.scss']
})
export class ManageUserOrAuthComponent implements OnInit, OnDestroy {
  luxId = 'BMT-manage-user-auth' + this.constructor.name;
  getManagingUserInfo$: Observable<IManageuser> = this.lux.get(getManagingUserInfo, this.luxId);
  userInfo: IManageuser;
  pageToShow = '';

  constructor(private lux: Lux<{ createManageUserState: ICreateManageUserState }>) {
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    this.getManagingUserInfo$.subscribe(userAndAdminInfo => {
      this.userInfo = userAndAdminInfo;
      this.pageToShow = this.userInfo.selectedAction;
    });
  }

}
