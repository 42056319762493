import { NgModule } from '@angular/core';
import { ClientSearchModule as CSLibModule } from '@roosevelt/client-ui-lib';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsComponent } from './reports.component';
import { ReportsContainer } from './reports.container';
import { MassActionsReportComponent } from './mass-actions-report/mass-actions-report.component';
import { MassActionsReportContainer } from './mass-actions-report/mass-actions-report-container';
import { OverageDependentComponent } from './overage-dependent/overage-dependent.component';
import { OverageDependentContainer } from './overage-dependent/overage-dependent.container';
import { IReportsState } from '../store/reports/state';
import { initialReportsState } from '../store/reports/initialState';
import { CustomGroupReportComponent } from './custom-group-report/custom-group-report.component';
import { RouterModule } from '@angular/router';
import { CustomGroupReportContainer } from 'src/app/reports/custom-group-report/custom-group-report.container';
import { ButtonModule } from '@roosevelt/common-ui-lib/button';
import { InputModule } from '@roosevelt/common-ui-lib/input';
import { LoaderModule } from '@roosevelt/common-ui-lib/loader';
import { SelectModule } from '@roosevelt/common-ui-lib/select';
import { PaginatorModule } from '@roosevelt/common-ui-lib/paginator';
import { Lux } from '@roosevelt/common-ui-lib/core';

@NgModule({
  declarations: [
    ReportsContainer,
    ReportsComponent,
    MassActionsReportComponent,
    MassActionsReportContainer,
    OverageDependentComponent,
    OverageDependentContainer,
    CustomGroupReportComponent,
    CustomGroupReportContainer
  ],
  imports: [
    CSLibModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    SelectModule,
    ButtonModule,
    LoaderModule,
    RouterModule,
    PaginatorModule
  ],
  providers: [DatePipe]
})
export class ReportsModule {
  constructor(lux: Lux<{ reportsState: IReportsState }>) {
    const reportsState = JSON.parse(sessionStorage.getItem('reports-state'));
    lux.register('reportsState', reportsState ? reportsState : initialReportsState);
    lux.allChanges().subscribe(state => sessionStorage.setItem('reports-state', JSON.stringify(state.reportsState)));
  }
}
