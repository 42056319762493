import { Component, OnDestroy, OnInit } from '@angular/core';
import { IClient, IClients } from '@roosevelt/client-ui-lib/lib/client-search/store/state';
import { Observable } from 'rxjs';
import { getSelectedAdminPlan } from 'src/app/login/store/selectors';
import { ILoginState } from 'src/app/login/store/state';
import { CreateManageUserAsync } from 'src/app/store/createManageUser/async';
import { errorsFetchingClients, fetchingClients, getClientsOfAdmin, getManagingUserInfo } from 'src/app/store/createManageUser/selector';
import { ICreateManageUserState, IManageuser } from 'src/app/store/createManageUser/state';
import { Lux } from '@roosevelt/common-ui-lib/core';

@Component({
  selector: 'bmt-profile-authorizations',
  templateUrl: './authorizations.component.html',
  styleUrls: ['./authorizations.component.scss']
})
export class AuthorizationsComponent implements OnInit, OnDestroy {
  luxId = 'BMT-profile-auth-comp' + this.constructor.name;
  getManagingUserInfo$: Observable<IManageuser> = this.lux.get(getManagingUserInfo, this.luxId);
  getClients$: Observable<IClients> = this.lux.get(getClientsOfAdmin, this.luxId);
  errorsFetchingClients$: Observable<string> = this.lux.get(errorsFetchingClients, this.luxId);
  fetchingClients$: Observable<boolean> = this.lux.get(fetchingClients, this.luxId);
  getSelectedAdminPlan$: Observable<string> = this.lux.get(getSelectedAdminPlan, this.luxId);
  userInfo: IManageuser;

  totalRecords: number = 0;
  // currentPage = 1;
  defaultPageSize = 50;
  selectedIndex = 0;
  sortField: string = '';
  sortOrder: string = 'ASC';

  groupData: any;
  errorsFetchingClients: string;
  fetchingClients: boolean;
  selectedClient = '';
  groupSelected: IClient;
  selectedAdminPlan: string;

  constructor(private lux: Lux<{ createManageUserState: ICreateManageUserState, login: ILoginState }>,
              private createManageUserAsync: CreateManageUserAsync) {
  }

  ngOnDestroy(): void {
    this.lux.destroy(this.luxId);
  }

  ngOnInit(): void {
    this.errorsFetchingClients$.subscribe(errs => this.errorsFetchingClients = errs);
    this.fetchingClients$.subscribe(fetching => this.fetchingClients = fetching);
    this.getManagingUserInfo$.subscribe(userAndAdminInfo => {
      this.userInfo = userAndAdminInfo;
      this.getClients(0);
    });
    // this.getClients$.subscribe(clientsInfo => {
    //   console.log(clientsInfo.pagination.totalRecords);
    //   this.totalRecords = clientsInfo.pagination && clientsInfo.pagination.totalRecords ? clientsInfo.pagination.totalRecords : 0;
    //   this.groupData = clientsInfo.clients;
    // });
    this.getSelectedAdminPlan$.subscribe(adminPlan => {
      this.selectedAdminPlan = adminPlan;
    });
  }

  getClients(offset) {
    const req = {
      planAcronym: this.userInfo.planAcronyms,
      offset: offset,
      limit: this.defaultPageSize
    };
    this.createManageUserAsync.getClients(req).subscribe(clientsData => {
      if (clientsData) {
        this.totalRecords = clientsData.pagination && clientsData.pagination.totalRecords ? clientsData.pagination.totalRecords : 0;
        this.groupData = clientsData.clients;
      }
    });
  }

  getMoreClients(page: any) {
    // this.currentPage = page.page;
    this.getClients(page.pageIndex >= 1 ? (page.pageIndex * this.defaultPageSize) : 0);
  }

  openSubGroupAuths(group: IClient) {
    this.selectedClient = group.specifiedId;
    this.groupSelected = group;
  }

}
