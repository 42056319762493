import { IClientPlanDetails, IContractReqObj, IMassActionState, IMassTerminateOrReinstate, IMassTransfer } from './state';
import { IClient, ISubClient } from '@roosevelt/client-ui-lib/lib/client-search/store/state';

export const getMassTerminateOrReinstateReq =
  (state: { massAction: IMassActionState }): IMassTerminateOrReinstate => state.massAction.massTerminateOrReinstateReq;

export const getErrors = (state: { massAction: IMassActionState }): string => state.massAction.errorMessage;

export const isMassActionCallSuccess = (state: { massAction: IMassActionState }): boolean => state.massAction.isMassActionCallDone;

export const isAnyActionInProgress = (state: { massAction: IMassActionState }): boolean => state.massAction.isCallingMassAction;

export const getSelectedAction = (state: { massAction: IMassActionState }): string => state.massAction.actionSelected;

export const getMassTransferReq =
  (state: { massAction: IMassActionState }): IMassTransfer => state.massAction.massTransferReq;

export const getDestinationClientDetails = (state: { massAction: IMassActionState }): IClient => state.massAction.destinationClient;

export const getDestinationSelectedSubClients = (state: { massAction: IMassActionState }):
  ISubClient[] => state.massAction.destinationSubClients;

export const getSourceClientDetails = (state: { massAction: IMassActionState }): IClient => state.massAction.sourceClient;

export const getSourceSelectedSubClient = (state: { massAction: IMassActionState }):
  ISubClient[] => state.massAction.sourceSubClients;

export const isShowDestinationClientDetails = (state: { massAction: IMassActionState }):
  boolean => state.massAction.isShowDestinationClient;

export const getContractRequestObj = (state: {
  massAction: IMassActionState
}): IContractReqObj => state.massAction.contractInfo.requestObj;

export const getContractResponse = (state: {
  massAction: IMassActionState
}): IClientPlanDetails => state.massAction.contractInfo.responseObj;

export const isFecthingContractInfo = (state: {
  massAction: IMassActionState
}): boolean => state.massAction.contractInfo.isFecthingContractInfo;
